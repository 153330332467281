import React, { useState } from "react";
import "./styles.css";
import NoraAI_icon from '../../images/nora-ai-logo-black.png';
import Search from "../../images/search.svg";
import Filter from "../../images/filter.svg";
import Dashboard from "../../images/dashboard.svg";
import addDashboardLogo from "../../images/addDashboardLogo.svg";
import profileDashboardLogo from "../../images/profileDashboardLogo.svg";
import DownArrow from "../../images/down-arrow.svg";
import MobileMenu from "../../images/mobile-menu.svg";
import PostInternships from "../postInternship/index.js";
import Profile from "../../screens/Profiles/Profile.jsx";

function Navbar({ search ,showSidebar}) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  function profileBtnClicked() {
    setShowProfile(!showProfile);
  }

  return (
    <div className="navbar">
        {!showProfile && (
            <div className={isNavExpanded ? "top-bar expanded" : "top-bar"}>
            <div className="left-container">
              <img src={NoraAI_icon} alt="" className="logo" />
            </div>
            <ul className="nav-elements">
              <li style={{marginRight: "20px"}}>
                <a href="/dashboard">Dashboard</a>
              </li>
              <li><a href="/login">Logout</a></li>
            </ul>
            <button
              className="hamburger mobile-menu"
              onClick={() => setIsNavExpanded(!isNavExpanded)}
            >
              <img src={MobileMenu} alt="" className="mobile-menu" />
            </button>
          </div>
        )}
      {showProfile && (
            <div className="profile__overlay-div">
              <Profile onExitClick = {profileBtnClicked}/>
            </div>
          )}
    </div>
  );
}

export default Navbar;
