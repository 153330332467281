import React from "react";
import LocationIcon from "../../images/homepage/locationIcon.png";
import EmailIcon from "../../images/homepage/emailIcon.png";
import TelephoneIcon from "../../images/telephone.svg";

const RejectedCandidates = ({
  applicants,
  selectedApplicant,
  setSelectedApplicant,
  selectedApplicants,
  setSelectedApplicants,
  toggleApplicantSelection,
  sortApplicantsByDate,
  sortOrder,
  setPopupApplicant,
  getStatus
}) => {
  // Filter for "Rejected" applicants
  const rejectedApplicants = applicants.filter(
    (applicant) => getStatus(applicant) === "Rejected"
  );

  return (
    <>
      {/* LEFT SIDE: The list of rejected applicants */}
      <div className="kanban-applicant-container">
        <div className="kanban-applicant-header">
          <div className="kanban-select-all">
            <input
              type="checkbox"
              checked={selectedApplicants.length === applicants.length}
              onChange={() =>
                setSelectedApplicants(
                  selectedApplicants.length === applicants.length
                    ? []
                    : [...applicants]
                )
              }
            />
            <span>
              Select All {rejectedApplicants.length} Applicants
            </span>
          </div>
          <div
            className="kanban-date-sort"
            onClick={sortApplicantsByDate}
            style={{ cursor: "pointer" }}
          >
            Date {sortOrder === "asc" ? "↑" : "▼"}
          </div>
        </div>

        {Array.isArray(applicants) && rejectedApplicants.length > 0 ? (
          <>
            {rejectedApplicants.map((applicant) => (
              <React.Fragment key={applicant.id}>
                <div
                  className={`kanban-applicant-row ${
                    selectedApplicant === applicant ? "selected" : ""
                  }`}
                  onClick={() => setSelectedApplicant(applicant)}
                >
                  <div className="kanban-applicant-header">
                    <div className="kanban-applicant-selector">
                      <input
                        type="checkbox"
                        checked={selectedApplicants.includes(applicant)}
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleApplicantSelection(applicant);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div className="kanban-applicant-name">
                        {applicant.name}
                      </div>
                    </div>
                    <div className="kanban-applicant-date">
                      {new Date(applicant.appliedDate).toLocaleDateString()}
                    </div>
                  </div>
                  
                  {applicant.location && (
                    <div className="kanban-applicant-location">
                      <img
                        src={LocationIcon}
                        alt="Location Icon"
                        className="location-icon"
                      />
                      {applicant.location}
                    </div>
                  )}

                  <div className="kanban-applicant-links">
                    {applicant.linkedIn && (
                      <a
                        href={applicant.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kanban-applicant-link"
                        style={{ marginLeft: "40px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        LinkedIn
                      </a>
                    )}
                    {applicant.resumeFileName && (
                      <a
                        href={applicant.resumeFileName}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kanban-applicant-link"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Resume
                      </a>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </>
        ) : (
          <p>No applicants available.</p>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "65%" }}>
        <div className="candidate-action-container">
          <div className="candidate-status-label">Status: Rejected</div>
          <div className="candidate-action-buttons">
            <button
              className="reject-button"
              onClick={() => setPopupApplicant(selectedApplicant)}
            >
              Reject
            </button>
          </div>
        </div>

        <div className="candidate-details-container">
          {selectedApplicant && (
            <>
              <div className="candidate-name">{selectedApplicant.name}</div>
              <div className="candidate-contact-info" style={{ 
                  display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" 
                }}>

                <div className="contact-pill">
                  <img src={EmailIcon} alt="Email Icon" className="contact-icon" />
                  <span>{selectedApplicant.email || "N/A"}</span>
                </div>

                <div className="contact-pill">
                  <img src={TelephoneIcon} alt="Phone Icon" className="contact-icon" />
                  <span>{selectedApplicant.phone || "N/A"}</span>
                </div>
                
              </div>
              <div className="candidate-contact-info">
                <p style={{ marginTop: "2.5rem" }}>
                  <strong>Resume</strong>
                </p>
                <div style={{borderTop: "1px solid #ccc", width: "100%", marginTop: "-1rem", marginBottom: "0.5rem"}}/>
                {selectedApplicant.resumeFileName && (
                  <>
                    {selectedApplicant.resumeFileName.toLowerCase().endsWith(".docx") ? (
                      // If it's a .docx, display via Microsoft Office viewer
                      <iframe
                        key={`docx-${selectedApplicant.candidateToInterviewId}-${Date.now()}`} 
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                          selectedApplicant.resumeFileName
                        )}`}
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                        title="DOCX Preview"
                      />
                    ) : (
                      // Otherwise (e.g. PDF), display directly
                      <iframe
                        key={`pdf-${selectedApplicant.candidateToInterviewId}-${Date.now()}`} 
                        src={`${selectedApplicant.resumeFileName}#toolbar=0&navpanes=0&scrollbar=0`}
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                        title="Resume Preview"
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RejectedCandidates;