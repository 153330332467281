import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import closeButton from "../../images/closeButton.svg";
import { styled, StyledEngineProvider } from "@mui/material/styles";
import $ from "jquery";
import "./importedStyles.css";
import "./style.css";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate, useLocation } from "react-router-dom";

const Text = styled(Typography)(() => ({
  fontFamily: "Montserrat, san-serif !important",
  textTransform: "capitalize",
}));

const RequiredSpan = styled(Typography)(() => ({
  fontFamily: "Montserrat, san-serif !important",
  color: "#FF0000",
  display: "inline",
  fontSize: "20px !important",
  marginLeft: "3px",
}));

const style = {
  position: "absolute",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  p: 4,
  fontFamily: "Montserrat, san-serif !important",
};

const INDUSTRY_OPTIONS = [
  { display: "STEM", value: 1 },
  { display: "Business", value: 2 },
  { display: "Arts/ Humanities", value: 3 },
  { display: "Social Ecology", value: 4 },
  { display: "Undecided", value: 5 },
];

const MINIMUM_EDUCATION = [
  { display: "No Education Required", value: 0 },
  { display: "High School", value: 1 },
  { display: "Associate", value: 2 },
  { display: "Bachelors", value: 3 },
  { display: "Masters", value: 4 },
  { display: "Doctoral", value: 5 },
];

export default function postInternships({src}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [applyIdx, setApplyIdx] = useState(0);
  const [skillList, setSkillList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);

  const [internshipTitle, setInternshipTitle] = useState("");
  const [type, setType] = useState("full-time");
  const [industry, setIndustry] = useState(INDUSTRY_OPTIONS[0].value);
  const [description, setDescription] = useState("");
  const [duties, setDuties] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [paid, setPaid] = useState("");
  const [remote, setRemote] = useState("");

  const [minEducation, setMinEducation] = useState(MINIMUM_EDUCATION[0].value);
  const [additionalLanguageNeeded, setAdditionalLanguageNeeded] = useState("");
  const [isGPARequired, setIsGPARequired] = useState(0);
  const [gpa, setGpa] = useState("");
  const [
    isMinimumExperienceRequired,
    setIsMinimumExperienceRequired,
  ] = useState(0);
  const [minExperience, setMinExperience] = useState("0");
  const [isLocationRequired, setIsLocationRequired] = useState("");
  const [requiredCity, setRequiredCity] = useState("");
  const [requiredState, setRequiredState] = useState("");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [urgentHire, setUrgentHire] = useState("");
  const [authorizedToWork, setAuthorizedToWork] = useState("");
  const [sponsorEmployment, setSponsorEmployment] = useState("");

  const addInternshipEndpoint = process.env.REACT_APP_ADD_INTERNSHIP_ENDPOINT;

  if (applyIdx === 0 || applyIdx === 1 || applyIdx === 2) {
    style.overflow = "scroll";
  } else {
    style.overflow = "hidden";
  }

  function enterInput(e, func, list) {
    if (e.key === "Enter") {
      let value = e.target.value;
      func([...list, value]);
      e.target.value = "";
    }
  }

  function deleteInputItem(e, item, list, func) {
    const arr = list;
    const res = [];
    arr.forEach((i) => {
      if (i !== item) {
        res.push(i);
      }
    });
    func(res);
  }

  const dutiesRef = useRef(null);
  const descRef = useRef(null);
  const infoRef = useRef(null);

  const logDuties = () => {
    if (dutiesRef.current) {
      setDuties(dutiesRef.current.getContent());
    }
  };

  const logDescription = () => {
    if (descRef.current) {
      setDescription(descRef.current.getContent());
    }
  };

  const logInfo = () => {
    if (infoRef.current) {
      setAdditionalInformation(infoRef.current.getContent());
    }
  };

  function postInternshipCall() {
    const companyName = localStorage.getItem("companyName");

    const body = {
      companyName: companyName,
      internshipTitle: internshipTitle,
      internshipType: parseInt(industry),
      internshipDescription: description,
      internshipDuties: duties,
      additionalInformation:
        additionalInformation === "" ? additionalInformation : null,
      paid: paid === "yes" ? true : false,
      remote: remote === "yes" ? true : false,
      volunteer: paid === "yes" ? false : true,
      temporary: true,
      contract: true,
      partTime: type === "full-time" ? false : true,
      fullTime: type === "full-time" ? true : false,
      internshipCity: isLocationRequired === "yes" ? requiredCity : null,
      internshipState: isLocationRequired === "yes" ? requiredState : null,
      active: true,
      resume: true,
      veteranStatus: null,
      yearsOfExperience: parseInt(minExperience),
      ethnicity: 0,
      disabilityStatus: 0,
      gender: 0,
      usworkAuthorization: authorizedToWork === "yes" ? true : false,
      willSponsor: sponsorEmployment === "yes" ? true : false,
      minimumEducation: parseInt(minEducation),
      gpa: isGPARequired === "yes" ? gpa : null,
      city: isLocationRequired === "yes" ? requiredCity : null,
      state: isLocationRequired === "yes" ? requiredState : null,
      drivingLicense: drivingLicense === "yes" ? true : false,
      urgentHire: urgentHire === "yes" ? true : false,
      requirementSkills: skillList,
      requirementLanguages:
        additionalLanguageNeeded === "yes" ? languagesList : null,
    };

    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    $.post(`${addInternshipEndpoint}`, JSON.stringify(body), (res) => {
      // Reset all states
      setApplyIdx(0);
      setOpen(false);
      setSkillList([]);
      setInternshipTitle("");
      setType("");
      setIndustry(INDUSTRY_OPTIONS[0]);
      setDescription("");
      setDuties("");
      setAdditionalInformation("");
      setPaid("");
      setRemote("");
      setMinEducation("");
      setAdditionalLanguageNeeded("");
      setIsGPARequired("");
      setIsMinimumExperienceRequired(0);
      setMinExperience("");
      setIsLocationRequired("");
      setRequiredCity("");
      setRequiredState("");
      setDrivingLicense("");
      setUrgentHire("");
      setAuthorizedToWork("");
      setSponsorEmployment("");
      setLanguagesList([]);
      if (location.pathname === "/dashboard") {
        window.location.reload();
      } else {
        navigate("/dashboard");
      }
    }).fail((error) => {
      console.log("[API] /internship/add fail");
    });
  }

  const searchCity = (searchValue) => {
    setRequiredCity(searchValue);
  };

  const searchState = (searchValue) => {
    setRequiredState(searchValue);
  };

  return (
    <div className="post">
   {
  src === "" ? (
    <button className="post-button" onClick={handleOpen}>
      Post Internship
    </button>
  ) : (
    <div className="sidebar-item" onClick={handleOpen}>
    <img src={src} alt="" className="navbar__img addimg" />
    <p className="sidebar-label">Post Internship</p>
  </div>
  )
}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              id="modal-modal-title"
              variant="h4"
              component="h1"
              style={{ fontWeight: 600 }}
            >
              Post Internship
            </Text>
            <Button onClick={handleClose}>
              <img src={closeButton} alt="" />
            </Button>
          </Box>

          <Divider sx={{ mt: 3 }} />
          <StyledEngineProvider injectFirst>
            <div className="apply-progress">
              <span
                className={`${applyIdx === 0 ? "apply-progress-active" : ""}`}
              >
                <div className="apply-progress-circle" />
                <p className="apply-step">Internship Details</p>
              </span>
              <div className="apply-progress-line"></div>
              <span
                className={`${applyIdx === 1 ? "apply-progress-active" : ""}`}
              >
                <div className="apply-progress-circle" />
                <p className="apply-step">Requirements</p>
              </span>
              <div className="apply-progress-line"></div>
              <span
                className={`${applyIdx === 2 ? "apply-progress-active" : ""}`}
              >
                <div className="apply-progress-circle" />
                <p className="apply-step">Review & Submit</p>
              </span>
            </div>

            <div className="apply-wrapper">
              {applyIdx === 0 && (
                <div style={{ marginTop: "1rem" }} className="apply-page-long">
                  <Text
                    id="modal-modal-title"
                    variant="h4"
                    component="h3"
                    style={{ fontWeight: 600, marginBottom: "2rem" }}
                  >
                    Internship Details
                  </Text>
                  <div className="internship-details-grid">
                    <div className="internship-details-grid-left">
                      <form
                        id="form"
                        onSubmit={(e) => {
                          // TODO: Move onto next input
                          e.preventDefault();
                        }}
                      >
                        <label className="question-title">
                          Internship Title<RequiredSpan>*</RequiredSpan>
                        </label>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="text"
                            value={internshipTitle}
                            onChange={(e) => setInternshipTitle(e.target.value)}
                          />
                        </span>

                        <div className="select-container">
                          <span style={{ alignItems: "baseline" }}>
                            <label className="question-title">
                              Type<RequiredSpan>*</RequiredSpan>
                            </label>
                            <select
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="full-time">Full-time</option>
                              <option value="part-time">Part-time</option>
                            </select>
                          </span>

                          <span style={{ alignItems: "baseline" }}>
                            <label className="question-title">
                              Industry<RequiredSpan>*</RequiredSpan>
                            </label>
                            <select
                              value={industry}
                              onChange={(e) => {
                                setIndustry(e.target.value);
                              }}
                            >
                              {INDUSTRY_OPTIONS.map((option) => {
                                return (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.display}
                                  </option>
                                );
                              })}
                            </select>
                          </span>
                        </div>

                        <label className="question-title">
                          Description<RequiredSpan>*</RequiredSpan>
                        </label>
                        <Editor
                          apiKey="jc8yk8kr00di4mb9o4q4qmctbxvmw8yko01y30p42h6xvsqp"
                          onInit={(evt, editor) => (descRef.current = editor)}
                          value={description}
                          onEditorChange={() => logDescription()}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />

                        <label className="question-title">
                          Duties<RequiredSpan>*</RequiredSpan>
                        </label>
                        <Editor
                          apiKey="jc8yk8kr00di4mb9o4q4qmctbxvmw8yko01y30p42h6xvsqp"
                          onInit={(evt, editor) => (dutiesRef.current = editor)}
                          value={duties}
                          onEditorChange={() => logDuties()}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />

                        <label className="question-title">
                          Additional Information
                        </label>
                        <Editor
                          apiKey="jc8yk8kr00di4mb9o4q4qmctbxvmw8yko01y30p42h6xvsqp"
                          onInit={(evt, editor) => (infoRef.current = editor)}
                          value={additionalInformation}
                          onEditorChange={() => logInfo()}

                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </form>
                    </div>
                    <div className="internship-details-grid-right">
                      <label className="question-title">
                        Is this a paid internship?<RequiredSpan>*</RequiredSpan>
                      </label>
                      <div className="align-radio ">
                        <input
                          className="input-radio paid"
                          type="radio"
                          id="paid-yes"
                          name="paid"
                          value="yes"
                          checked={paid === "yes" ? true : false}
                          onChange={(e) => setPaid(e.target.value)}
                        />
                        <label className="radio-label" htmlFor="paid-yes">
                          Yes
                        </label>
                      </div>
                      <div className="align-radio ">
                        <input
                          className="input-radio paid"
                          type="radio"
                          id="paid-no"
                          name="paid"
                          value="no"
                          checked={paid === "no" ? true : false}
                          onChange={(e) => setPaid(e.target.value)}
                        />
                        <label className="radio-label" htmlFor="paid-no">
                          No
                        </label>
                      </div>

                      <label className="question-title">
                        Is this a remote internship?
                        <RequiredSpan>*</RequiredSpan>
                      </label>
                      <div className="align-radio">
                        <input
                          className="input-radio remote"
                          type="radio"
                          id="remote-yes"
                          name="remote"
                          value="yes"
                          checked={remote === "yes" ? true : false}
                          onChange={(e) => setRemote(e.target.value)}
                        />
                        <label className="radio-label" htmlFor="remote-yes">
                          Yes
                        </label>
                      </div>
                      <div className="align-radio ">
                        <input
                          className="input-radio remote"
                          type="radio"
                          id="remote-no"
                          name="remote"
                          value="no"
                          checked={remote === "no" ? true : false}
                          onChange={(e) => setRemote(e.target.value)}
                        />
                        <label className="radio-label" htmlFor="remote-no">
                          No
                        </label>
                      </div>
                    </div>

                    <div className="apply-btn-wrapper">
                      {applyIdx === 0 ? (
                        <p></p>
                      ) : (
                        <button
                          onClick={() => setApplyIdx(applyIdx - 1)}
                          className="backbtn"
                        >
                          Back
                        </button>
                      )}
                      {applyIdx === 1 ? (
                        <button className="nextbtn">Next</button>
                      ) : (
                        <button
                          onClick={() => {
                            setApplyIdx(applyIdx + 1);
                          }}
                          className="nextbtn"
                          disabled={
                            internshipTitle === "" ||
                            description === "" ||
                            duties === "" ||
                            paid === "" ||
                            remote === ""
                          }
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {applyIdx === 1 && (
                <div style={{ marginTop: "1rem" }} className="apply-page-long">
                  <Text
                    id="modal-modal-title"
                    variant="h4"
                    component="h3"
                    style={{ fontWeight: 600, marginBottom: "2rem" }}
                  >
                    Requirements
                  </Text>
                  <div className="requirements-details-grid">
                    <div className="requirements-details-grid-left">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <label className="question-title">Skills</label>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="skill-input"
                            type="text"
                            onKeyDown={(e) => {
                              enterInput(e, setSkillList, skillList);
                            }}
                            placeholder="Type a skill and press enter"
                          />
                        </span>

                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className="skills-container">
                            {skillList.map((skill) => {
                              if (skill !== "") {
                                return (
                                  <div key={skill} className="skill">
                                    <p>{skill}</p>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        deleteInputItem(
                                          e,
                                          skill,
                                          skillList,
                                          setSkillList
                                        );
                                      }}
                                    >
                                      <img
                                        src={closeButton}
                                        alt="close button"
                                      ></img>
                                    </button>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </span>

                        <span style={{ alignItems: "baseline" }}>
                          <label className="question-title">
                            Minimum Education<RequiredSpan>*</RequiredSpan>
                          </label>
                          <select
                            value={minEducation}
                            onChange={(e) => {
                              setMinEducation(e.target.value);
                            }}
                          >
                            {MINIMUM_EDUCATION.map((education) => {
                              return (
                                <option
                                  value={education.value}
                                  key={education.value}
                                >
                                  {education.display}
                                </option>
                              );
                            })}
                          </select>
                        </span>

                        <label className="question-title">
                          Additional language beside English needed?
                          <RequiredSpan>*</RequiredSpan>
                        </label>
                        <div className="align-radio ">
                          <input
                            className="input-radio Language"
                            type="radio"
                            id="language-yes"
                            name="language"
                            value="yes"
                            checked={
                              additionalLanguageNeeded === "yes" ? true : false
                            }
                            onChange={(e) =>
                              setAdditionalLanguageNeeded(e.target.value)
                            }
                          />
                          <label className="align-radio" htmlFor="language-yes">
                            Yes
                          </label>
                        </div>
                        <div className="align-radio ">
                          <input
                            className="input-radio Language"
                            type="radio"
                            id="language-no"
                            name="language"
                            value="no"
                            checked={
                              additionalLanguageNeeded === "no" ? true : false
                            }
                            onChange={(e) =>
                              setAdditionalLanguageNeeded(e.target.value)
                            }
                          />
                          <label className="radio-label" htmlFor="language-no">
                            No
                          </label>
                        </div>

                        {additionalLanguageNeeded === "yes" && (
                          <div>
                            <label className="question-language">
                              Language<RequiredSpan>*</RequiredSpan>
                            </label>
                            <input
                              className="skill-input"
                              type="text"
                              onKeyDown={(e) => {
                                enterInput(e, setLanguagesList, languagesList);
                              }}
                              placeholder="Type a language and press enter"
                            />
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div className="skills-container">
                                {languagesList.map((language) => {
                                  if (language !== "") {
                                    return (
                                      <div key={language} className="skill">
                                        <p>{language}</p>
                                        <button
                                          type="button"
                                          onClick={(e) => {
                                            deleteInputItem(
                                              e,
                                              language,
                                              languagesList,
                                              setLanguagesList
                                            );
                                          }}
                                        >
                                          <img
                                            src={closeButton}
                                            alt="close button"
                                          ></img>
                                        </button>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </span>
                          </div>
                        )}

                        <label className="question-title">
                          Is there a GPA requirement?
                          <RequiredSpan>*</RequiredSpan>
                        </label>
                        <div className="align-radio ">
                          <input
                            className="input-radio gpa"
                            type="radio"
                            id="gpa-yes"
                            name="gpa"
                            value="yes"
                            checked={isGPARequired === "yes" ? true : false}
                            onChange={(e) => setIsGPARequired(e.target.value)}
                          />
                          <label className="radio-label" htmlFor="gpa-yes">
                            Yes
                          </label>
                        </div>
                        <div className="align-radio ">
                          <input
                            className="input-radio gpa"
                            type="radio"
                            id="gpa-no"
                            name="gpa"
                            value="no"
                            checked={isGPARequired === "no" ? true : false}
                            onChange={(e) => setIsGPARequired(e.target.value)}
                          />
                          <label className="radio-label" htmlFor="gpa-no">
                            No
                          </label>
                        </div>

                        {isGPARequired === "yes" && (
                          <div>
                            <label className="question-gpa">
                              GPA<RequiredSpan>*</RequiredSpan>
                            </label>
                            <input
                              className="enter-gpa"
                              type="text"
                              value={gpa}
                              onChange={(e) => setGpa(e.target.value)}
                            />
                          </div>
                        )}

                        <label className="question-title">
                          Is there a minimum years of experience needed?
                          <RequiredSpan>*</RequiredSpan>
                        </label>
                        <div className="align-radio ">
                          <input
                            className="input-radio yearsExperience"
                            type="radio"
                            id="yearsExperience-yes"
                            name="yearsExperience"
                            value="yes"
                            checked={
                              isMinimumExperienceRequired === "yes"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              setIsMinimumExperienceRequired(e.target.value)
                            }
                          />
                          <label
                            className="radio-label"
                            htmlFor="yearsExperience-yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="align-radio ">
                          <input
                            className="input-radio yearsExperience"
                            type="radio"
                            id="yearsExperience-no"
                            name="yearsExperience"
                            value="no"
                            checked={
                              isMinimumExperienceRequired === "no"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setIsMinimumExperienceRequired(e.target.value);
                            }}
                          />
                          <label
                            className="radio-label"
                            htmlFor="yearsExperience-no"
                          >
                            No
                          </label>
                        </div>

                        {isMinimumExperienceRequired === "yes" && (
                          <div>
                            <label className="question-numberOfYears">
                              Number of Years<RequiredSpan>*</RequiredSpan>
                            </label>
                            <input
                              className="enter-numberOfYears"
                              type="text"
                              value={minExperience}
                              onChange={(e) => {
                                setMinExperience(e.target.value);
                              }}
                            />
                          </div>
                        )}

                        <label className="question-title">
                          Are there any locations requirements?
                          <RequiredSpan>*</RequiredSpan>
                        </label>
                        <div className="align-radio ">
                          <input
                            className="input-radio locationRequirements"
                            type="radio"
                            id="locationRequirements-yes"
                            name="locationRequirements"
                            value="yes"
                            checked={
                              isLocationRequired === "yes" ? true : false
                            }
                            onChange={(e) =>
                              setIsLocationRequired(e.target.value)
                            }
                          />
                          <label
                            className="radio-label"
                            htmlFor="locationRequirements-yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="align-radio ">
                          <input
                            className="input-radio locationRequirements"
                            type="radio"
                            id="locationRequirements-no"
                            name="locationRequirements"
                            value="no"
                            checked={isLocationRequired === "no" ? true : false}
                            onChange={(e) =>
                              setIsLocationRequired(e.target.value)
                            }
                          />
                          <label
                            className="radio-label"
                            htmlFor="locationRequirements-no"
                          >
                            No
                          </label>
                        </div>

                        {isLocationRequired === "yes" && (
                          <div>
                            <label className="question-city">
                              City<RequiredSpan>*</RequiredSpan>
                            </label>
                            <input
                              className="enter-city"
                              type="text"
                              value={requiredCity}
                              onChange={(e) => {
                                searchCity(e.target.value);
                              }}
                            />
                            <label className="question-state">
                              State<RequiredSpan>*</RequiredSpan>
                            </label>
                            <input
                              className="enter-State"
                              type="text"
                              value={requiredState}
                              onChange={(e) => searchState(e.target.value)}
                            />
                          </div>
                        )}

                        <div>
                          <label className="question-title">
                            Driving License required
                            <RequiredSpan>*</RequiredSpan>
                          </label>
                          <div className="align-radio ">
                            <input
                              className="input-radio drivingLicense"
                              type="radio"
                              id="drivingLicense-yes"
                              name="drivingLicense"
                              value="yes"
                              checked={drivingLicense === "yes" ? true : false}
                              onChange={(e) =>
                                setDrivingLicense(e.target.value)
                              }
                            />
                            <label
                              className="radio-label"
                              htmlFor="drivingLicense-yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="align-radio ">
                            <input
                              className="input-radio drivingLicense"
                              type="radio"
                              id="drivingLicense-no"
                              name="drivingLicense"
                              value="no"
                              checked={drivingLicense === "no" ? true : false}
                              onChange={(e) =>
                                setDrivingLicense(e.target.value)
                              }
                            />
                            <label
                              className="radio-label"
                              htmlFor="drivingLicense-no"
                            >
                              No
                            </label>
                          </div>

                          <label className="question-title">
                            Urgent Hire?<RequiredSpan>*</RequiredSpan>
                          </label>
                          <div className="align-radio ">
                            <input
                              className="input-radio urgentHire"
                              type="radio"
                              id="urgentHire-yes"
                              name="urgentHire"
                              value="yes"
                              checked={urgentHire === "yes" ? true : false}
                              onChange={(e) => {
                                setUrgentHire(e.target.value);
                              }}
                            />
                            <label
                              className="radio-label"
                              htmlFor="urgentHire-yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="align-radio ">
                            <input
                              className="input-radio urgentHire"
                              type="radio"
                              id="urgentHire-no"
                              name="urgentHire"
                              value="no"
                              checked={urgentHire === "no" ? true : false}
                              onChange={(e) => {
                                setUrgentHire(e.target.value);
                              }}
                            />
                            <label
                              className="radio-label"
                              htmlFor="urgentHire-no"
                            >
                              No
                            </label>
                          </div>
                        </div>

                        <Text
                          id="modal-modal-title"
                          variant="h4"
                          component="h3"
                          style={{ fontWeight: 600, marginTop: "2rem" }}
                        >
                          Work Authorization
                        </Text>
                      </form>
                    </div>

                    <div className="requirements-details-grid-down">
                      <label className="question-title">
                        Should the interns be authorized to work in the US?
                        <RequiredSpan>*</RequiredSpan>
                      </label>
                      <div className="align-radio ">
                        <input
                          className="input-radio"
                          type="radio"
                          id="authorized-yes"
                          name="authorized"
                          value="yes"
                          checked={authorizedToWork === "yes" ? true : false}
                          onChange={(e) => {
                            setAuthorizedToWork(e.target.value);
                          }}
                        />
                        <label className="radio-label" htmlFor="authorized-yes">
                          Yes
                        </label>
                      </div>
                      <div className="align-radio ">
                        <input
                          className="input-radio"
                          type="radio"
                          id="authorized-no"
                          name="authorized"
                          value="no"
                          checked={authorizedToWork === "no" ? true : false}
                          onChange={(e) => {
                            setAuthorizedToWork(e.target.value);
                          }}
                        />
                        <label className="radio-label" htmlFor="authorized-no">
                          No
                        </label>
                      </div>

                      <label className="question-title">
                        As a company are you able to sponsor for employment visa
                        status (eg-H1B visa status) now or in the future if
                        required?<RequiredSpan>*</RequiredSpan>
                      </label>
                      <div className="align-radio">
                        <input
                          className="input-radio"
                          type="radio"
                          id="sponsorship-yes"
                          name="sponsorship"
                          value="yes"
                          checked={sponsorEmployment === "yes" ? true : false}
                          onChange={(e) => {
                            setSponsorEmployment(e.target.value);
                          }}
                        />
                        <label
                          className="radio-label"
                          htmlFor="sponsorship-yes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="align-radio ">
                        <input
                          className="input-radio"
                          type="radio"
                          id="sponsorship-no"
                          name="sponsorship"
                          value="no"
                          checked={sponsorEmployment === "no" ? true : false}
                          onChange={(e) => {
                            setSponsorEmployment(e.target.value);
                          }}
                        />
                        <label className="radio-label" htmlFor="sponsorship-no">
                          No
                        </label>
                      </div>
                    </div>

                    <div className="apply-btn-wrapper">
                      {applyIdx === 0 ? (
                        <p></p>
                      ) : (
                        <button
                          onClick={() => setApplyIdx(applyIdx - 1)}
                          className="backbtn"
                        >
                          Back
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setApplyIdx(applyIdx + 1);
                        }}
                        className="nextbtn"
                        disabled={
                          isGPARequired === "" ||
                          (isGPARequired === "yes" && gpa === "") ||
                          additionalLanguageNeeded === "" ||
                          (additionalLanguageNeeded === "yes" &&
                            languagesList.length === 0) ||
                          isMinimumExperienceRequired === "" ||
                          isLocationRequired === "" ||
                          (isLocationRequired === "yes" &&
                            requiredCity === "" &&
                            requiredState === "") ||
                          drivingLicense === "" ||
                          urgentHire === "" ||
                          authorizedToWork === "" ||
                          sponsorEmployment === ""
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {applyIdx === 2 && (
                <div style={{ marginTop: "1rem" }} className="apply-page-long">
                  <Text
                    id="modal-modal-title"
                    variant="h4"
                    component="h3"
                    style={{ fontWeight: 600, marginBottom: "2rem" }}
                  >
                    Review & Submit
                  </Text>
                  <div className="internship-review-details-grid">
                    <div className="internship-review-details-grid-left">
                      <Text
                        className="question-title"
                        style={{ fontWeight: 500 }}
                      >
                        {internshipTitle}
                      </Text>
                    </div>
                    <div className="internship-review-details-grid-right">
                      <div className="internship-review-full-details-grid">
                        <Text>Type</Text>
                        <Text>{type}</Text>
                        <Text>Industry</Text>
                        <Text>{INDUSTRY_OPTIONS[industry - 1].display}</Text>
                        <Text>Remote</Text>
                        <Text>{remote}</Text>
                        <Text>Paid</Text>
                        <Text>{paid}</Text>
                        <Text>Description</Text>
                        <Text
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                        <Text>Duties</Text>
                        <Text dangerouslySetInnerHTML={{ __html: duties }} />
                        <Text>Additional Info</Text>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: additionalInformation,
                          }}
                        />
                      </div>
                      <Text
                        className="internship-review-edit-text"
                        onClick={() => {
                          setApplyIdx(0);
                        }}
                      >
                        Edit
                      </Text>
                    </div>
                    <div className="internship-review-details-grid-left">
                      <Text
                        className="question-title"
                        style={{ fontWeight: 500 }}
                      >
                        Requirements
                      </Text>
                    </div>
                    <div className="internship-review-details-grid-right">
                      <div className="internship-review-full-details-grid">
                        <Text>Skill(s)</Text>
                        <Text>{skillList.join(", ")}</Text>
                        <Text>Minimum Education</Text>
                        <Text>{MINIMUM_EDUCATION[minEducation].display}</Text>
                        <Text>Language(s)</Text>
                        <Text>
                          {additionalLanguageNeeded === "yes"
                            ? languagesList.join(", ")
                            : "English"}
                        </Text>
                        <Text>GPA</Text>
                        <Text>
                          {isGPARequired === "yes" ? gpa : "Not Required"}
                        </Text>
                        <Text>Minimum Experience</Text>
                        <Text>
                          {isMinimumExperienceRequired === "yes"
                            ? minExperience
                            : "Not Required"}
                        </Text>
                        <Text>Location</Text>
                        <Text>
                          {isLocationRequired === "yes"
                            ? requiredCity + ", " + requiredState
                            : "Not Required"}
                        </Text>
                        <Text>Drivers License</Text>
                        <Text>
                          {drivingLicense === "yes"
                            ? drivingLicense
                            : "Not Required"}
                        </Text>
                        <Text>Urgent Hire</Text>
                        <Text>
                          {urgentHire === "yes" ? urgentHire : "Not Required"}
                        </Text>
                      </div>
                      <Text
                        className="internship-review-edit-text"
                        onClick={() => {
                          setApplyIdx(1);
                        }}
                      >
                        Edit
                      </Text>
                    </div>
                    <div className="internship-review-details-grid-left">
                      <Text
                        className="question-title"
                        style={{ fontWeight: 500 }}
                      >
                        Work Authorization
                      </Text>
                    </div>
                    <div className="internship-review-details-grid-right">
                      <div className="internship-review-full-details-grid">
                        <Text>Authorized to Work in US</Text>
                        <Text>{authorizedToWork}</Text>
                        <Text>Sponsor/ Will Sponsor in Future</Text>
                        <Text>{sponsorEmployment}</Text>
                      </div>
                      <Text
                        className="internship-review-edit-text"
                        onClick={() => {
                          setApplyIdx(1);
                        }}
                      >
                        Edit
                      </Text>
                    </div>
                  </div>
                  <div className="apply-btn-wrapper">
                    {applyIdx === 0 ? (
                      <p></p>
                    ) : (
                      <button
                        onClick={() => setApplyIdx(applyIdx - 1)}
                        className="backbtn"
                      >
                        Back
                      </button>
                    )}
                    <button
                      onClick={() => postInternshipCall()}
                      className="nextbtn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </StyledEngineProvider>
        </Box>
      </Modal>
    </div>
  );
}
