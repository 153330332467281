import $ from "jquery";

function storeToken(apiResponse) {
  localStorage.setItem("token", apiResponse.token);
}

async function isLoggedIn() {
  $.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `bearer ${getToken()}`,
    },
  });
  // let result;
  try {
    await $.ajax(process.env.REACT_APP_GET_USER, {
      type: "GET",
      data: {},
    });
    return true;
  } catch (err) {
    return false;
  }
}

function logout() {
  window.localStorage.setItem("userEmail", null);
  window.localStorage.removeItem("token");
}

function getToken() {
  return window.localStorage.getItem("token");
}

export { storeToken, isLoggedIn, logout, getToken };
