import React from "react";
import LocationIcon from "../../images/homepage/locationIcon.png";
import EmailIcon from "../../images/homepage/emailIcon.png";
import TelephoneIcon from "../../images/telephone.svg";
import { Oval } from "react-loader-spinner";

const SourcedApplicants = ({
  applicants,
  selectedApplicant,
  setSelectedApplicant,
  selectedApplicants,
  setSelectedApplicants,
  toggleApplicantSelection,
  sortApplicantsByDate,
  sortOrder,
  handlePushToResumeScreening,
  setPopupInterviewApplicant,
  setPopupBulkInterviewApplicant,
  setPopupApplicant,
  getStatus,
  isLoading,
  currentPage,
  applicantsPerPage,
  setCurrentPage
}) => {
  // Filter for "Submitted" applicants
  const sourcedApplicants = applicants.filter(
    (applicant) => getStatus(applicant) === "Submitted"
  );

  // Compute paginated applicants for the current page
  const paginatedApplicants = sourcedApplicants.slice(
    (currentPage - 1) * applicantsPerPage,
    currentPage * applicantsPerPage
  );

  const totalPages = Math.ceil(sourcedApplicants.length / applicantsPerPage);

  const handlePushToInterview = () => {
    if (selectedApplicants.length === 1) {
      // If only one candidate is selected, use the existing single interview popup
      setPopupInterviewApplicant(selectedApplicant);
    } else if (selectedApplicants.length > 1) {
      // If multiple candidates are selected, use the new bulk interview popup
      setPopupBulkInterviewApplicant(selectedApplicants);
    } else {
      // No candidates selected
      alert("Please select at least one candidate.");
    }
  };

  return (
    <>
      <div className="kanban-applicant-container">
        <div className="kanban-applicant-header">
          <div className="kanban-select-all">
            <input
              type="checkbox"
              checked={selectedApplicants.length === sourcedApplicants.length && sourcedApplicants.length > 0}
              onChange={() =>
                setSelectedApplicants(
                  selectedApplicants.length === sourcedApplicants.length
                    ? [] // Deselect all if already all selected
                    : [...sourcedApplicants] // Select only sourced applicants, not all applicants
                )
              }
            />
            <span>Select All {sourcedApplicants.length} Applicants</span>
          </div>
          <div
            className="kanban-date-sort"
            onClick={sortApplicantsByDate}
            style={{ cursor: "pointer" }}
          >
            Date {sortOrder === "asc" ? "↑" : "▼"}
          </div>
        </div>

        {sourcedApplicants.length > 0 ? (
          paginatedApplicants.map((applicant) => (
            <React.Fragment key={applicant.id}>
              <div
                className={`kanban-applicant-row ${
                  selectedApplicant === applicant ? "selected" : ""
                }`}
                onClick={() => setSelectedApplicant(applicant)}
              >
                <div className="kanban-applicant-header">
                  <div className="kanban-applicant-selector">
                    <input
                      type="checkbox"
                      checked={selectedApplicants.includes(applicant)}
                      onChange={(e) => {
                        e.stopPropagation();
                        toggleApplicantSelection(applicant);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className="kanban-applicant-name">{applicant.name}</div>
                  </div>
                  <div className="kanban-applicant-date">
                    {new Date(applicant.appliedDate).toLocaleDateString()}
                  </div>
                </div>

                {applicant.location && (
                  <div className="kanban-applicant-location">
                    <img
                      src={LocationIcon}
                      alt="Location Icon"
                      className="location-icon"
                    />
                    {applicant.location}
                  </div>
                )}

                <div className="kanban-applicant-links">
                  {applicant.linkedIn && (
                    <a
                      href={applicant.linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="kanban-applicant-link"
                      style={{ marginLeft: "40px" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      LinkedIn
                    </a>
                  )}
                  {applicant.resumeFileName && (
                    <a
                      href={applicant.resumeFileName}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="kanban-applicant-link"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Resume
                    </a>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <p>No applicants available.</p>
        )}

        {totalPages > 1 && (
          <div style={{ marginTop: "1rem", textAlign: "center" }}>
            {Array.from(
              { length: Math.ceil(sourcedApplicants.length / applicantsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  style={{
                    margin: "0 4px",
                    padding: "6px 10px",
                    border: "1px solid #ccc",
                    backgroundColor: currentPage === index + 1 ? "#333" : "#fff",
                    color: currentPage === index + 1 ? "#fff" : "#000",
                    borderRadius: "4px",
                    cursor: "pointer"
                  }}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "65%" }}>
        <div className="candidate-action-container">
          <div className="candidate-status-label">Status: Applied</div>
          <div className="candidate-action-buttons">
          <button
              className="push-to-resume-screening"
              onClick={handlePushToInterview}
              disabled={!selectedApplicant && selectedApplicants.length === 0}
            >
              {selectedApplicants.length === 1 || selectedApplicants.length === 0
              ? "Push to Interview With Nora ↓"
              : `Push ${selectedApplicants.length} to Interview With Nora ↓`}
            </button>
            <button
              className="push-to-resume-screening"
              onClick={() => {
                // If applicants are selected via checkboxes, use those
                // Otherwise fall back to the single selected applicant
                const applicantsToProcess = selectedApplicants.length > 0 
                  ? selectedApplicants 
                  : (selectedApplicant ? [selectedApplicant] : []);
                  
                handlePushToResumeScreening(applicantsToProcess);
              }}
              disabled={isLoading || (!selectedApplicant && selectedApplicants.length === 0)}
            >
              {isLoading ? (
                <Oval color="#00BFFF" height={20} width={20} />
              ) : (
                <>
                  {selectedApplicants.length > 0 
                    ? `Screen ${selectedApplicants.length} Resume${selectedApplicants.length > 1 ? 's' : ''} ↓` 
                    : "Push to Resume Screening ↓"}
                </>
              )}
            </button>
            <button
              className="reject-button"
              onClick={() => setPopupApplicant(selectedApplicant)}
            >
              Reject
            </button>
          </div>
        </div>
        <div className="candidate-details-container">
          {selectedApplicant && (
            <>
              <div className="candidate-name">{selectedApplicant.name}</div>
              <div
                className="candidate-contact-info"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem"
                }}
              >
                <div className="contact-pill">
                  <img src={EmailIcon} alt="Email Icon" className="contact-icon" />
                  <span>{selectedApplicant.email || "N/A"}</span>
                </div>
                <div className="contact-pill">
                  <img src={TelephoneIcon} alt="Phone Icon" className="contact-icon" />
                  <span>{selectedApplicant.phone || "N/A"}</span>
                </div>
              </div>
              <div className="candidate-contact-info">
                {selectedApplicant.resumeFileName && (
                  <>
                    <p style={{ marginTop: "2.5rem" }}>
                        <strong>Resume</strong>
                    </p>
                    <div
                      style={{
                        borderTop: "1px solid #ccc",
                        width: "100%",
                        marginTop: "-1rem",
                        marginBottom: "0.5rem"
                      }} />
                  </>
                )}
                {selectedApplicant.resumeFileName && (
                  <>
                    {selectedApplicant.resumeFileName.toLowerCase().endsWith(".docx") ? (
                      <iframe
                        key={`docx-${selectedApplicant.candidateToInterviewId}-${Date.now()}`} 
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                          selectedApplicant.resumeFileName
                        )}`}
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                        title="DOCX Preview"
                      />
                    ) : (
                      <iframe
                        key={`pdf-${selectedApplicant.candidateToInterviewId}-${Date.now()}`} 
                        src={`${selectedApplicant.resumeFileName}#toolbar=0&navpanes=0&scrollbar=0`}
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                        title="Resume Preview"
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SourcedApplicants;