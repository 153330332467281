
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {useState, useRef} from "react";
import "./style.css";
import axios from "axios";
import Footer from "../../components/footer";
import NavigationBar from '../../components/navigation';
const BlogWriter = () => {
    const editorRef = useRef(null);
    const logContent = () => {
    if (editorRef.current) {
      setContent(editorRef.current.getContent());
    }
  };
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const [featured, setFeatured] = useState(false)
    const [message, setMessage] = useState("");

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file && file.type.startsWith('image/')) {
          setThumbnail(file); 
      }
  };

const handleSubmit = async () => {
    let base64Image = null;
    if (thumbnail) {
        base64Image = await convertToBase64(thumbnail);
    }
    const blogData = {
        Title: title,
        Description: content,
        Image: base64Image, 
        Featured: featured,
    };
    try {
        const response = await axios.post(process.env.REACT_APP_POST_BLOG, blogData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setMessage("Blog has been posted!");
    } catch (error) {
        setMessage("Error posting blog");
        console.error("Error posting blog:", error);
    }
};
const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
    return (
        <>
        <NavigationBar/>
                <div className="flex flex-col justify-between p-10 bg-off-white blog-writer">
        <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} className="p-3 w-400 mb-5 rounded-xl border-none" />
        <input type="file" accept="image/*" onChange={handleImageChange} className="p-3 w-400 mb-5" />
        <div className="flex flex-row mb-5 gap-2"> 
        <input type="checkbox" checked={featured} onChange={(e) => setFeatured(e.target.checked)} className="mr-2 top-0 " />
        <label className="text-lg text-bold">
                Featured
            </label>
            </div>  
        <Editor
        apiKey='jc8yk8kr00di4mb9o4q4qmctbxvmw8yko01y30p42h6xvsqp'
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={content}
        onEditorChange={() => logContent()}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
<p className={`${message === "Blog has been posted!" ? "text-green-500" : "text-red-500"} mt-5`}> {message} </p>      <button className="bg-ig-blue p-4 text-lg text-white w-400 rounded-xl" onClick={handleSubmit} > Post </button>
 </div>
 <Footer/>
 </>
    )
}
export default BlogWriter;