import React, {useState, useEffect, useRef} from 'react';
import 'react-multi-carousel/lib/styles.css';

import Chatbot from '../../components/Chatbot';

import CraneOperator from '../../images/homepage/craneOperator.png';
import ExecutiveAssistant from '../../images/homepage/executiveAssistant.png';
import SWE from '../../images/homepage/swe.png';
import ResearchAssistant from '../../images/homepage/researchAssistant.png';
import MarketingIntern from '../../images/homepage/marketingIntern.png';

import HowItWorks1Gif from '../../images/homepage/howItWorks1.gif';
import HowItWorks2Gif from '../../images/homepage/howItWorks2.gif';
import HowItWorks3Gif from '../../images/homepage/howItWorks3.gif';
import DemoCallBackground from '../../images/homepage/demoCallBackground.png';

import './styles.css';
import Footer from '../../components/footer';

import NavigationBar from '../../components/navigation';
import { useMediaQuery } from '@mui/material';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import AudioPlayer from '../../components/homePageAudioPlayer';
import { useInView } from 'react-intersection-observer';

export default function HomePage() {
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [counts, setCounts] = useState({
    statusQuoHours: 0,
    statusQuoHires: 0,
    noraHours: 0,
    noraHires: 0
  });
  
  const { ref: comparisonRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      const timer = setInterval(() => {
        setCounts(prev => {
          // Stop at 40 hours
          if (prev.noraHours >= 40) {
            clearInterval(timer);
            return prev;
          }
          return {
            ...prev,
            noraHours: prev.noraHours + 1,
             // Increment candidates hired every 20 hours
             noraHires: Math.floor((prev.noraHours + 1) / 20),
          };
        });
      }, 300);
  
      return () => clearInterval(timer);
    }
  }, [inView]);

  const [openIndex, setOpenIndex] = useState(null);
  const faqRefs = useRef([]);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    faqRefs.current.forEach((ref, idx) => {
      if (ref) {
        ref.style.maxHeight = openIndex === idx ? `${ref.scrollHeight}px` : '60px';
      }
    });
  }, [openIndex]);

  const faqs = [
    {
      question: "Can Nora use my questions?",
      answer: "Yes, Nora can! Recruiters can customize, edit, delete, or reorder the interview script Nora generates."
    },
    {
      question: "How much does Nora cost?",
      answer: "Nora is highly economical! We offer a tiered pricing structure with very low per-interview costs for our bulk plan. Schedule a demo for more details"
    },
    {
      question: "How do candidates like Nora?",
      answer: "We ensure a smooth candidate experience with constant feedback iteration, live chat support, and real-time API notifications!"
    },
    {
      question: "What positions can Nora cover?",
      answer: "Any position! Simply input a job description, and Nora generates relevant interview questions, assessing candidates for all type of positions"
    }
  ];

  return (
    <div className="hero_container">
      <Chatbot open={chatbotOpen} closeDialog={() => setChatbotOpen(false)}/>
      <NavigationBar />

      <h1 className="hero_title">
        Meet Nora, your AI Interviewer
      </h1>
      <p className="hero_subtitle">
        Interview 1000 candidates by tomorrow with AI
        <div style={{ width: '50%', justifyContent: 'center', marginTop: '20px', marginLeft: '25%' }}>
          <AudioPlayer />
        </div>
      </p>

      <div className="btn-container">
        <button className="demo_request_button"
        onClick={() => window.open('https://calendly.com/nora-hq/demo-questionnaire', '_blank')}>
                Request a demo
        </button>
      </div>

      <div className="outsourcing-solution">
        <h2 className="outsourcing-heading">"Houston, your recruiters just got 100x stronger!"</h2>
      </div>

      <div className="comparison-container" ref={comparisonRef}>
        <h2 style={{ fontSize: '18px' }}>For example, In a week:</h2>
        <div className="blocks">
          {/* Block 1 */}
          <div className="block status-quo">
            <h3>The status quo</h3>
            <p style={{ marginBottom: '50px' }}>~20 hours of manual screening to fill one position.</p>
            <div className="metrics">
              <div>
                <p className="number">{counts.noraHours}</p>
                <p>Hours spent screening</p>
              </div>
              <div>
                <p className="number">{counts.noraHires === 0 ? counts.noraHires : `${counts.noraHires}?`}</p>
                <p>Candidates hired</p>
              </div>
            </div>
          </div>

          {/* Block 2 */}
          <div className="block with-nora">
            <h3>With Nora</h3>
            <p style={{ marginBottom: '30px' }}>~1 hour reviewing Nora-generated interview scores to fill one position.</p>
            <div className="metrics">
              <div>
                <p className="number">{counts.noraHours}</p>
                <p>Hours spent reviewing</p>
              </div>
              <div>
                <p className="number">{counts.noraHours} (yup!)</p>
                <p>Candidates hired</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="outsourcing-solution">
        <h2 className="outsourcing-heading">Nora helps in 3 simple steps: </h2>
      </div>

      <div className="how-it-works-1-section">
        <div className="how-it-works-1-content">
          <div className="how-it-works-1-text">
            <h2 className="how-it-works-1-heading">
              Post a position.
            </h2>
            <ul className="how-it-works-1-description">
              <li>Upload a job description</li>
              <li>Nora will generate a custom interview script</li>
              <li>Add/edit/delete/reorder interview script to tailor the interview questions</li>
            </ul>
          </div>
          <div className="how-it-works-1-partnership">
            <img src={HowItWorks1Gif} alt="How it works 1" className='image1-styling'/>
          </div>
        </div>
      </div>

      <div className="how-it-works-1-section">
        <div className="how-it-works-1-content">
        <div className="how-it-works-1-text">
            <h2 className="how-it-works-1-heading">Interview candidates with Nora.</h2>
            <ul className="how-it-works-1-description">
              <li>Send Candidates AI Interview link</li>
              <li>Candidates take interview whenever, wherever</li>
              <li>Simple and hyperrealistic - answer through speech, next question through speech</li>
            </ul>
          </div>
          <div className="how-it-works-1-partnership">
            <img src={HowItWorks2Gif} alt="How it works 2" className='image1-styling'/>
          </div>
        </div>
      </div>


      <div className="how-it-works-1-section">
        <div className="how-it-works-1-content">
        <div className="how-it-works-1-text">
            <h2 className="how-it-works-1-heading">Evaluate 1000 candidates in a day.</h2>
            <ul className="how-it-works-1-description">
              <li>Nora will generate a custom interview score for each candidate</li>
              <li>Receive detailed analysis and scoring on each candidate</li>
              <li>Hiring just made 100x easier.</li>
            </ul>

            {!isMobile && (
              <button className="demo_request_button" 
              onClick={() => window.open('https://calendly.com/nora-hq/demo-questionnaire', '_blank')}>
                Request a demo
              </button>
            )}
          </div>
          <div className="how-it-works-1-partnership">
            <img src={HowItWorks3Gif} alt="How it works 2" className='image1-styling'/>
          </div>
        </div>
      </div>

      <div className="cta-container">
        <div className="cta-text">Candidates enjoy interviewing with Nora!</div>
      </div>

      <div className="youtube-slider">
        <Swiper
          spaceBetween={30}
          slidesPerView={isMobile ? 3: 3}
          modules={[Pagination, Navigation]}
          pagination={{ clickable: true }}
          navigation
          style={{ maxWidth: '1400px', margin: 'auto' }}
        >
          <SwiperSlide>
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/u6PcufrcGiM?si=_8Rt_nv83KmSyR7n"
              title="Marketing Interview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/KG__RKrjhjA?si=GaC2wcAg52tzu-ph"
              title="SWE Interview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/M6ZbEo8XykA?si=J2-8IjcAYnSULdl-"
              title="Sales Rep interview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/HzwrLCRnB2U?si=cyitPq7Dokc0381o"
              title="Product Designer Interview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/010Uix9NBZI?si=dpbhLyAC-x6Mqkon"
              title="Fullstack SWE Interview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="carousel">
        <Swiper
          spaceBetween={20}
          slidesPerView={isMobile ? 1: 3}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 3000, 
            disableOnInteraction: false, 
          }}
          pagination={{ clickable: true }}
          style={{ maxWidth: '1500px', margin: 'auto', paddingBottom: '70px' }}
        >
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={CraneOperator}
                  alt="Crane Operator"
                  className="founder-image"
                />
              </div>
              <div className="card-content">
                <h3>Ed Davidson</h3>
                <p className="title">Crane Operator Interview</p>
                <p className="quote">
                  “It was my first time doing an interview with AI and it was really straightforward. 
                  Got relevant questions to the position”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={SWE}
                  alt="Software Engineer"
                  className="founder-image"
                />
              </div>
              <div className="card-content">
                <h3>Brijesh Muthumanickum</h3>
                <p className="title">Software Engineer Interview</p>
                <p className="quote">
                  “Nora asked me really technical questions and one react question actually got me lol. 
                  I also like the transcript feature and as a SWE I can tell Nora is the future of interviews!”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={ExecutiveAssistant}
                  alt="Executive Assistant"
                  className="founder-image"
                />
              </div>
              <div className="card-content">
                <h3>Lara Kaila Reario</h3>
                <p className="title">Executive Assistant Interview</p>
                <p className="quote">
                  “It was really convenient to take an interview with Nora - I was sent the interview link and
                  could take the interview at my own timing and comfort!”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={ResearchAssistant}
                  alt="Research Assistant"
                  className="founder-image"
                />
              </div>
              <div className="card-content">
                <h3>Ali Salaheldin</h3>
                <p className="title">Biomedical Research Interview</p>
                <p className="quote">
                  “As someone who gets stressed out about taking interviews, I geniunely felt much calmer
                  while taking an interview with Nora. It gave me the space to think clearly and answer questions confidently”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={MarketingIntern}
                  alt="Marketing Intern"
                  className="founder-image"
                />
              </div>
              <div className="card-content">
                <h3>Mikaila Bramlette</h3>
                <p className="title">Marketing Intern Interview</p>
                <p className="quote">
                  “This was a great interview experience, I was able to successfully complete it without any issues. This was actually fun!”
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <h2 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '30px' }}>FAQ</h2>
        {faqs.map((faq, index) => (
          <div
            className={`faq-item ${openIndex === index ? 'open' : ''}`}
            key={index}
            ref={el => faqRefs.current[index] = el}
            onClick={() => toggleFAQ(index)}
            style={{ maxHeight: '60px' }} // Set initial maxHeight
          >
            <div className="faq-question">
              {faq.question}
              <span className="faq-toggle">{openIndex === index ? '▼' : '▲'}</span>
            </div>
            <div className="faq-answer">{faq.answer}</div>
          </div>
        ))}
      </div>


      <div className="component_five_container" 
        style={{
          ...(isMobile
            ? { width: '85%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }
            : {
                backgroundImage: `url(${DemoCallBackground})`,
                width: '85%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }),
        }}
      >
        <h2 className="calendly_header" id="component_five">Ready to elevate your interviewing game?</h2>
        <div className="calendly_subtext">
          Simplify interviewing. Smarter hiring.
        </div>
        <button className="demo_request_button" 
        onClick={() => window.open('https://calendly.com/nora-hq/demo-questionnaire', '_blank')}>
          Request a demo
        </button>
      </div>

      <Footer />
    </div>
  );
}
