import axios from 'axios';

const downloadCandidatesAsCSV = async (applicants) => {
  const headers = [
    'Name',
    'Email',
    'Location',
    'LinkedIn',
    'Status',
    'Application Date',
    'Interview Score',
    'Overall Feedback',
    'Cultural Fit Feedback',
    'Technical Skill Feedback',
    'Soft Skill Feedback',
    'Must Have Feedback',
    'Technical Skills',
    'Major',
    'College'
  ];

  const fetchTechnicalDetails = async (applicant) => {
    try {
      // Download the file from the URL
      const fileResponse = await axios.get(applicant.resumeFileName, {
        responseType: 'blob', // Ensures the response is a binary blob
      });
  
      // Create FormData to handle file upload
      const formData = new FormData();
      if (fileResponse.data) {
        const fileBlob = fileResponse.data;
        const fileName = applicant.resumeFileName.split('/').pop(); // Extract the file name from the URL
        const file = new File([fileBlob], fileName, { type: fileBlob.type });
        formData.append("files", file); // Attach the downloaded file
      } else {
        console.error(`Failed to download resume file for ${applicant.name}`);
        return {
          technicalSkills: 'null',
          major: 'null',
          college: 'null',
        };
      }
  
      // Call the API with the FormData
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/chat/downloadCandidate`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type
          },
        }
      );
  
      const data = response.data[0]; // Assuming the response is an array
      return {
        technicalSkills: data.technicalSkills || 'null',
        major: data.major || 'null',
        college: data.college || 'null',
      };
    } catch (error) {
      console.error(`Error fetching technical details for ${applicant.name}:`, error);
      return {
        technicalSkills: 'null',
        major: 'null',
        college: 'null',
      };
    }
  };

  // Process applicants data into CSV rows
  const getStatus = (applicant) => {
    if (applicant.rejected) return 'Rejected';
    if (applicant.hired) return 'Hired';
    if (applicant.interviewWithNora === null) return 'Submitted';
    if (applicant.interviewWithNora === true && applicant.interviewResults === null) return 'Awaiting';
    if (applicant.interviewWithNora === true && applicant.interviewResults !== null) return 'Interviewed';
    return 'N/A';
  };

  const rows = await Promise.all(
    applicants.map(async (applicant) => {
      const interviewResults = applicant.interviewResults ? JSON.parse(applicant.interviewResults) : null;
      const { technicalSkills, major, college } = await fetchTechnicalDetails(applicant);

      return [
        applicant.name || 'null',
        applicant.email || 'null',
        applicant.location || 'null',
        applicant.linkedIn || 'null',
        getStatus(applicant),
        applicant.appliedDate
          ? new Date(applicant.appliedDate).toLocaleDateString('en-US') // Format application date
          : 'null',
        interviewResults ? interviewResults.overallScore : 'null',
        interviewResults ? interviewResults.overallFeedback : 'null',
        interviewResults ? interviewResults.culturalFitFeedback : 'null',
        interviewResults ? interviewResults.technicalSkillFeedback : 'null',
        interviewResults ? interviewResults.softSkillFeedback : 'null',
        interviewResults ? interviewResults.mustHaveSummary : 'null',
        technicalSkills,
        major,
        college
      ];
    })
  );

  // Combine headers and rows
  const csvContent = [
    headers,
    ...rows
  ].map(row => 
    row.map(cell => {
      // Handle cells that might contain commas or quotes
      if (typeof cell === 'string' && (cell.includes(',') || cell.includes('"') || cell.includes('\n'))) {
        return `"${cell.replace(/"/g, '""')}"`;
      }
      return cell;
    }).join(',')
  ).join('\n');

  // Create and trigger download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', `candidates_${new Date().toISOString().split('T')[0]}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadCandidatesAsCSV;