import React from "react";
import LocationIcon from "../../images/homepage/locationIcon.png";
import NoraAI_icon from "../../images/nora-ai-logo-black.png";
import downArrow from "../../images/down-arrow.svg";
import EmailIcon from "../../images/homepage/emailIcon.png";
import TelephoneIcon from "../../images/telephone.svg";

const NoraInterviewCandidates = ({
  applicants,
  sortedApplicants,
  paginatedApplicants,
  selectedApplicant,
  setSelectedApplicant,
  selectedApplicants,
  setSelectedApplicants,
  toggleApplicantSelection,
  sortApplicantsByDate,
  sortOrder,
  currentPage,
  applicantsPerPage,
  setCurrentPage,
  getStatus,
  handlePushToTopCandidate,
  setPopupApplicant
}) => {
  const totalPages = Math.ceil(sortedApplicants.length / applicantsPerPage);

  return (
    <>
      <div className="kanban-applicant-container">
        <div className="kanban-applicant-header">
          <div className="kanban-select-all">
            <input
              type="checkbox"
              checked={selectedApplicants.length === applicants.length}
              onChange={() =>
                setSelectedApplicants(
                  selectedApplicants.length === applicants.length ? [] : [...applicants]
                )
              }
            />
            <span>
              Select All{" "}
              {applicants.filter(
                (applicant) =>
                  getStatus(applicant) === "Awaiting" ||
                  getStatus(applicant) === "Interviewed"
              ).length}{" "}
              Applicants
            </span>
          </div>
          <div
            className="kanban-date-sort"
            onClick={sortApplicantsByDate}
            style={{ cursor: "pointer" }}
          >
            Date {sortOrder === "asc" ? "↑" : "▼"}
          </div>
        </div>

        {Array.isArray(sortedApplicants) && sortedApplicants.length > 0 ? (
          <>
            {paginatedApplicants.map((applicant) => (
              <React.Fragment key={applicant.id}>
                <div
                  className={`kanban-applicant-row ${
                    selectedApplicant === applicant ? "selected" : ""
                  }`}
                  onClick={() => setSelectedApplicant(applicant)}
                >
                  <div className="kanban-applicant-header">
                    <div className="kanban-applicant-selector">
                      <input
                        type="checkbox"
                        checked={selectedApplicants.includes(applicant)}
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleApplicantSelection(applicant);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div className="kanban-applicant-name">
                        {applicant.name}
                      </div>
                    </div>
                    <div className="kanban-applicant-date">
                      {new Date(applicant.appliedDate).toLocaleDateString()}
                    </div>
                  </div>
                  
                  {applicant.location && (
                    <div className="kanban-applicant-location">
                      <img
                        src={LocationIcon}
                        alt="Location Icon"
                        className="location-icon"
                      />
                      {applicant.location}
                    </div>
                  )}

                  <div className="kanban-applicant-links">
                    {applicant.linkedIn && (
                      <a
                        href={applicant.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kanban-applicant-link"
                        style={{ marginLeft: "40px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        LinkedIn
                      </a>
                    )}
                    {applicant.resumeFileName && (
                      <a
                        href={applicant.resumeFileName}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="kanban-applicant-link"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Resume
                      </a>
                    )}
                    <span
                      className={`kanban-score-badge ${
                        applicant.interviewResults &&
                        JSON.parse(applicant.interviewResults).overallScore
                          ? "scored"
                          : "awaiting"
                      }`}
                    >
                      {applicant.interviewResults
                        ? JSON.parse(applicant.interviewResults).overallScore
                        : "Awaiting"}
                    </span>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </>
        ) : (
          <p>No applicants available.</p>
        )}

        {totalPages > 1 && (
          <div style={{ marginTop: "1rem", textAlign: "center" }}>
            {Array.from(
              { length: Math.ceil(sortedApplicants.length / applicantsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  style={{
                    margin: "0 4px",
                    padding: "6px 10px",
                    border: "1px solid #ccc",
                    backgroundColor: currentPage === index + 1 ? "#333" : "#fff",
                    color: currentPage === index + 1 ? "#fff" : "#000",
                    borderRadius: "4px",
                    cursor: "pointer"
                  }}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "65%" }}>
        <div className="candidate-action-container">
          <div className="candidate-status-label">
            Status: Interviewed with Nora/Awaiting
          </div>
          <div className="candidate-action-buttons">
            <button
              className="push-to-resume-screening"
              onClick={() => handlePushToTopCandidate(selectedApplicant)}
            >
              Push to Top Candidates ↓
            </button>
            <button
              className="reject-button"
              onClick={() => setPopupApplicant(selectedApplicant)}
            >
              Reject
            </button>
          </div>
        </div>
        <div className="candidate-details-container">
          {selectedApplicant && (
            <>
              <div className="candidate-name">{selectedApplicant.name}</div>
              <div className="candidate-contact-info" style={{ 
                  display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" 
                }}>

                <div className="contact-pill">
                  <img src={EmailIcon} alt="Email Icon" className="contact-icon" />
                  <span>{selectedApplicant.email || "N/A"}</span>
                </div>

                <div className="contact-pill">
                  <img src={TelephoneIcon} alt="Phone Icon" className="contact-icon" />
                  <span>{selectedApplicant.phone || "N/A"}</span>
                </div>
                
              </div>
              <div className="candidate-contact-info">
                {selectedApplicant.interviewResults ? (
                  <div className="interview-results-container">
                    <p style={{ marginTop: "3rem" }}>
                      <strong>Interview Overview</strong>
                    </p>
                    <div
                      style={{
                        borderTop: "1px solid #ccc",
                        width: "100%",
                        marginBottom: "2.5rem"
                      }}
                    />
                    <p>
                      <strong>Overall Score:</strong>{" "}
                      {JSON.parse(selectedApplicant.interviewResults).overallScore}
                    </p>
                    <p>
                      <strong>Overall Feedback:</strong>{" "}
                      {JSON.parse(selectedApplicant.interviewResults).overallFeedback}
                    </p>
                    <p>
                      <strong>Cultural Fit Feedback:</strong>{" "}
                      {JSON.parse(selectedApplicant.interviewResults).culturalFitFeedback}
                    </p>
                    <p>
                      <strong>Technical Skill Feedback:</strong>{" "}
                      {JSON.parse(selectedApplicant.interviewResults).technicalSkillFeedback}
                    </p>
                    <p>
                      <strong>Soft Skill Feedback:</strong>{" "}
                      {JSON.parse(selectedApplicant.interviewResults).softSkillFeedback}
                    </p>
                    <p>
                      <strong>Must Have Feedback:</strong>{" "}
                      {JSON.parse(selectedApplicant.interviewResults).mustHaveSummary}
                    </p>

                    {selectedApplicant.videoFileName && (
                      <div style={{ margin: "3% 0" }}>
                        <p>
                          <strong>Audio Recording: </strong>
                        </p>
                        <mux-player
                          stream-type="on-demand"
                          playback-id={selectedApplicant.videoFileName}
                          metadata-video-title="Audio Playback"
                          audio
                        ></mux-player>
                      </div>
                    )}

                    {JSON.parse(selectedApplicant.interviewResults).questionAnalysis && (
                      <div className="accordion-container-qbqAnalysis">
                        <p>
                          <strong>Question By Question Analysis:</strong>
                        </p>
                        {JSON.parse(selectedApplicant.interviewResults).questionAnalysis.map(
                          (qa, index) => (
                            <div
                              key={index}
                              className="accordion-item-qbqAnalysis"
                              style={{
                                marginBottom: "1rem",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "1rem"
                              }}
                            >
                              <h2 className="accordion-header-qbqAnalysis">
                                <button
                                  className="accordion-button-qbqAnalysis collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse-${index}`}
                                >
                                  <p className="qa-question-text-qbqAnalysis">
                                    {qa.question}
                                  </p>
                                  <img
                                    src={downArrow}
                                    alt="Toggle"
                                    className="qa-toggle-icon-qbqAnalysis"
                                  />
                                </button>
                              </h2>
                              <div
                                id={`collapse-${index}`}
                                className="accordion-collapse-qbqAnalysis collapse"
                                aria-labelledby={`heading-${index}`}
                              >
                                <div className="accordion-body-qbqAnalysis">
                                  <p>
                                    <strong>Candidate Answer:</strong>{" "}
                                    {qa.candidateAnswer}
                                  </p>
                                  <p>
                                    <strong>Nora's Analysis:</strong>{" "}
                                    {qa.analysis}
                                  </p>
                                  <p>
                                    <strong>Nora's Score:</strong>{" "}
                                    {qa.candidateScore}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div style={{ marginTop: "3rem" }}>
                      <p style={{ fontWeight: "bold" }}>Interview Overview</p>
                      <div
                        style={{
                          borderTop: "1px solid #ccc",
                          width: "100%",
                          marginBottom: "1.5"
                        }}
                      />
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={NoraAI_icon}
                          alt="Nora AI Icon"
                          style={{
                            width: "40%",
                            height: "auto",
                            marginTop: "3rem"
                          }}
                        />
                      </div>
                      <p
                        style={{
                          fontSize: "1rem",
                          marginTop: "3rem",
                          textAlign: "center"
                        }}
                      >
                        Waiting for candidate's turn...
                      </p>
                    </div>
                  </>
                )}

                {selectedApplicant.resumeFileName && (
                  <>
                    {selectedApplicant.resumeFileName.toLowerCase().endsWith(".docx") ? (
                      // If it's a .docx, display via Microsoft Office viewer
                      <iframe
                        key={`docx-${selectedApplicant.candidateToInterviewId}-${Date.now()}`} 
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                          selectedApplicant.resumeFileName
                        )}`}
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                        title="DOCX Preview"
                      />
                    ) : (
                      // Otherwise (e.g. PDF), display directly
                      <iframe
                        key={`pdf-${selectedApplicant.candidateToInterviewId}-${Date.now()}`} 
                        src={`${selectedApplicant.resumeFileName}#toolbar=0&navpanes=0&scrollbar=0`}
                        width="100%"
                        height="500px"
                        style={{ border: "none" }}
                        title="Resume Preview"
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NoraInterviewCandidates;