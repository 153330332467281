import React from "react";
import NavigationBar from "../navigation";
import Footer from '../../components/footer'
import check_circle from "../../images/homepage/check-circle.svg";
import FAQBox from "../faq";
import { InlineWidget } from "react-calendly";
import "./styles.css";

export default function RecruiterPlans() {
  const faqs = [
    {
      question: "Can Intern Guys provide interns only in the summer?",
      answer:
        "No - we can provide interns year round!",
    },
    {
      question: "Do I have to pay the interns?",
      answer:
        "Usually its advised to do atleast minimum wage. But, if the internship is unpaid we advice part time and remote. But there are more legal requirements if the internship is unpaid",
    },
    {
      question: "Which positions can Intern Guys help with?",
      answer:
        "Our primary focus is in Business - Sales, Marketing & Finance interns as well as Tech - UI/UX, SWE, Mobile/Web, Machine learning interns",
    },
    {
      question: "How do we proceed with your services",
      answer:
        "Please book a call with us or feel free to email us at contact@internguys.com",
    }
  ];
  const scrollToBookACall = () => {
    let element = document.getElementsByClassName("plan-book-call-wrapper")[0];
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="recruiter-plans">
      <NavigationBar />
      <div className="plan-container-wrapper">
        {/* plan description section */}
        <div className="plan-background-wrapper">
          <div className="plan-description-wrapper">
            <h1 className="plan-header">
              Find the perfect interns for any role through our plans
            </h1>
            <div className="plan-A-description plan-description-box">
              <h2 className="plan-description-price-heading">
                Plan A (Post on Platform)<span className="plan-price"> $300/ month</span>
              </h2>
              <p className="plan-description-text">
                This plan allows you to gain unlimited posting privileges. After
                posting a position we'll email you each candidate's resume and
                contact information for easy follow-up. From there, you can take
                the reins and conduct further filtering and interviews to find
                the perfect fit.
                <br />
                <br />
                <span className="plan-description-text-note">
                  *Don’t need unlimited positions? You can also post one position for $100/month
                </span>
              </p>
              <div className="plan-signUp-cta-wrapper">
                <button className="plan-cta-button" onClick={scrollToBookACall}>
                  Book a Call
                </button>
              </div>
            </div>

            <div className="plan-B-description plan-description-box">
              <h2 className="plan-description-price-heading">
                Plan B (Outsource Hiring)<span className="plan-price"> $300-$1000/ per intern</span>
              </h2>
              <p className="plan-description-text">
                Share your intern requirements with us and we do the rest - 
                sourcing, filtration, interviewing and even
                onboarding candidates! We can get the interns to start on a particular date for you or get
                you 3 final candidates for each position after which you can
                interview them. With our fully customizable plan you can focus
                on growing your business to new heights!
                <br />
                <br />
                <span className="plan-description-text-note">
                  *Full money back gurantee!
                </span>
              </p>
              <div className="plan-book-cta-wrapper">
                <button className="plan-cta-button" onClick={scrollToBookACall}>
                  Book a Call
                </button>
              </div>
            </div>
          </div>

          {/* plan comparison section */}
          <div className="plan-comparison-wrapper">
            <h1 className="plan-header">Plan Comparison</h1>
            <div className="plan-comparison-box-container">
              <div className="plan-A-comparison">
                <div className="plan-A comparison-heading">
                  <h2>Plan A</h2>
                  <button className="plan-button" onClick={scrollToBookACall}>
                    Book a Call
                  </button>
                </div>
                <div className="comparison-content-wrapper">
                  <p>Includes all the following:</p>
                  <ul className="plan-comparison-content">
                    <li>
                      <img src={check_circle} />
                      Post unlimited positions on our platform
                    </li>
                    <li>
                      <img src={check_circle} />
                      Basic filtration
                    </li>
                    <li>
                      <img src={check_circle} />
                      Applicants emailed to you
                    </li>
                    <li>
                      <img src={check_circle} />
                      Cut the filter and interview candidates
                    </li>
                  </ul>
                </div>
              </div>

              <div className="plan-B-comparison">
                <div className="plan-B comparison-heading">
                  <h2>Plan B</h2>
                  <button
                    className="plan-button"
                    onClick={scrollToBookACall}
                  >
                    Book a Call
                  </button>
                </div>
                <div className="comparison-content-wrapper">
                  <p>Let us do the work for you! We will:</p>
                  <ul className="plan-comparison-content">
                    <li>
                      <img src={check_circle} />
                      Source top candidates
                    </li>
                    <li>
                      <img src={check_circle} />
                      Filter candidates
                    </li>
                    <li>
                      <img src={check_circle} />
                      Interview candidates
                    </li>
                    <li>
                      <img src={check_circle} />
                      Onboard interns
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* FAQ section */}
          <div className="plan-faq-wrapper">
            <h1 className="plan-header">FAQ</h1>

            {faqs.map((faq, index) => (
              <FAQBox
                key={index}
                question={faq.question}
                answer={faq.answer}
                isTop={index === 0}
                isBottom={index === faqs.length - 1}
              />
            ))}
          </div>

          {/* book a call, appointment section */}
          <div className="plan-book-call-wrapper">
            <h1 className="plan-header">
              Book a Free 1:1 Zoom Call With The Team
            </h1>
            <p className="book-a-call-description">
            We would be happy to answer any questions you might have about our services 
            and help you find which plan is right
            </p>
            <div className="calendly_container">
              <div className="calendly_inner_container">
                <InlineWidget
                  styles={{
                    position: "absolute",
                    top: "0",
                    bottom: "0",
                    width: "100%",
                    fontSize: "medium",
                  }}
                  pageSettings={{
                    backgroundColor: "ffffff",
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: false,
                    primaryColor: "00a2ff",
                    textColor: "4d5055",
                  }}
                  url="https://calendly.com/nora-hq/demo-questionnaire"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
