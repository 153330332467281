import React, { useState } from "react";
import "./style.css"; 
import axios from 'axios';
import { Oval } from 'react-loader-spinner'; 

function UploadCandidatesModal({ isOpen, onClose, onUpload, companyInterviewId }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [parsedResults, setParsedResults] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [loadingApplyApi, setLoadingApplyApi] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prev) => [...prev, ...files]);
  };

  const onSubmit = async () => {
    setLoadingApplyApi(true); 
    try {
      for (const result of parsedResults) {
        // Skip if required fields are missing
        if (!result.name || !result.email || !result.fileContent) {
            continue;
        }

        const payload = new FormData();
        payload.append("companyInterviewId", companyInterviewId); 
        payload.append("Name", result.name || "");
        payload.append("Email", result.email || "");
        payload.append("Phone", result.phone || "");
        payload.append("Location", result.location || "");
        payload.append("LinkedIn", result.linkedIn || ""); // Optional field
  
        // Convert base64 file content back to Blob if needed
        if (result.fileContent) {
            try {
              const byteCharacters = atob(result.fileContent); // Decode base64 string
              const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0)); // Convert to byte numbers
              const byteArray = new Uint8Array(byteNumbers); // Create byte array
              const blob = new Blob([byteArray], { type: result.contentType }); // Create Blob with correct MIME type
              payload.append("file", blob, result.fileName);
            } catch (error) {
              continue; // Skip this result if there's an issue
            }
        }
  
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/recruiter/get-interview-link`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
      }
      onClose(); // Close modal
      window.location.reload();
    } catch (error) {
      alert("An error occurred while uploading candidates. Please try again.");
    } 
  };

  const handleParseFiles = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });

    setLoading(true);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/chat/parseResumes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data && Array.isArray(response.data)) {
        setParsedResults(response.data); // Store parsed results directly
        setLoading(false);
      } else {
        console.error("No parsed data returned from the server.");
      }
    } catch (error) {
      console.error("Error parsing files:", error);
    } finally {
        setLoadingApplyApi(false); // Stop loader for onSubmit
    }
  };

  const handlePreviewFile = (base64Content, contentType) => {
    const blob = new Blob([Uint8Array.from(atob(base64Content), c => c.charCodeAt(0))], { type: contentType });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  if (!isOpen) return null; 

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {(loading || loadingApplyApi) && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", marginTop: '7%' }}>
            <Oval color="#00BFFF" height={60} width={60} />
            <span style={{ fontWeight: "500", marginTop: '15px'}}>
              {loadingApplyApi ? "Uploading candidates!" : "Parsing your uploaded documents - Extracting name, email, phone, LinkedIn profile, and location from the resumes!"}
            </span>
          </div>
        )}
        {!loading && !loadingApplyApi && (
          <>
            <button className="close-button" onClick={onClose}>
            &times;
            </button>
            <h2>Upload Candidate</h2>
        
            {parsedResults.length === 0 && (
            <>
                <p style={{ marginTop: '5%', fontSize: '14px', textAlign: 'left', alignSelf: 'flex-start', marginLeft: '5%' }}>
                <strong> Resume/CV * </strong><span style={{ fontSize: '10px' }}>(Accepted file types: PDF, DOCX)</span>
                </p>

                <div className="file-upload-area">
                <input
                    type="file"
                    multiple
                    accept=".pdf,.docx"
                    onChange={handleFileChange}
                />
                <p>Drop Files Here or Click to Upload</p>
                </div>

                <ul style={{ marginTop: '4%', textAlign: 'left', alignSelf: 'flex-start', marginLeft: '5%' }}>
                {uploadedFiles.length > 0 && (<p>Uploaded Documents</p>)}
                {uploadedFiles.map((file, index) => (
                    <li key={index}>
                    {file.name}
                    <button
                        style={{ marginLeft: "10px", background: "none", border: "none", color: "red", cursor: "pointer", fontSize: "16px" }}
                        onClick={() => {
                        const updatedFiles = [...uploadedFiles];
                        updatedFiles.splice(index, 1); // Remove the selected file
                        setUploadedFiles(updatedFiles); // Update state
                        }}
                    >
                        &times;
                    </button>
                    </li>
                ))}
                </ul>

                {uploadedFiles.length > 0 && (
                <button className="dashboard-add-job-button" onClick={handleParseFiles}>
                    Parse
                </button>
                )}
            </>
            )}

            {parsedResults.length > 0 && (
            <>
            <div style={{ marginTop: "20px", marginLeft: '5%', textAlign: 'left', alignSelf: 'flex-start', width: '90%' }}>

                <ul style={{ listStyle: "none", padding: 0 }}>
                    {parsedResults.map((result, index) => (
                        <li key={index} style={{ marginTop: "10px", borderBottom: "1px solid #ddd", paddingBottom: "10px" }}>

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <button style={{ background: "none", border: "none", color: "red", cursor: "pointer", fontSize: "20px" }}
                                    onClick={() => {
                                        const updatedResults = [...parsedResults];
                                        updatedResults.splice(index, 1); // Remove the selected parsed result
                                        setParsedResults(updatedResults); // Update state
                                    } }>
                                    &times;
                                </button>

                                <button className="accordion-button" onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}>
                                    <strong>{result.fileName}</strong>
                                </button>
                            </div>

                            {expandedIndex === index && (
                                <div className="resume-content" style={{ marginTop: "10px", marginLeft: '4%' }}>

                                    <div className="parsedResult-inputGroup">
                                        <label htmlFor={`name-${index}`} className="parsedResult-label">Name:</label>
                                        <input
                                            type="text"
                                            id={`name-${index}`}
                                            className="parsedResult-input"
                                            value={result.name || ""}
                                            onChange={(e) => {
                                                const updatedResults = [...parsedResults];
                                                updatedResults[index].name = e.target.value; // Update the name field
                                                setParsedResults(updatedResults); // Update the state
                                            } } />
                                    </div>

                                    <div className="parsedResult-inputGroup">
                                        <label htmlFor={`email-${index}`} className="parsedResult-label">Email:</label>
                                        <input
                                            type="email"
                                            id={`email-${index}`}
                                            className="parsedResult-input"
                                            value={result.email || ""}
                                            onChange={(e) => {
                                                const updatedResults = [...parsedResults];
                                                updatedResults[index].email = e.target.value; // Update the email field
                                                setParsedResults(updatedResults); // Update the state
                                            } } />
                                    </div>

                                    <div className="parsedResult-inputGroup">
                                        <label htmlFor={`phone-${index}`} className="parsedResult-label">Phone:</label>
                                        <input
                                            type="text"
                                            id={`phone-${index}`}
                                            className="parsedResult-input"
                                            value={result.phone || ""}
                                            onChange={(e) => {
                                                const updatedResults = [...parsedResults];
                                                updatedResults[index].phone = e.target.value; // Update the phone field
                                                setParsedResults(updatedResults); // Update the state
                                            } } />
                                    </div>

                                    <div className="parsedResult-inputGroup">
                                        <label htmlFor={`location-${index}`} className="parsedResult-label">Location:</label>
                                        <input
                                            type="text"
                                            id={`location-${index}`}
                                            className="parsedResult-input"
                                            value={result.location || ""}
                                            onChange={(e) => {
                                                const updatedResults = [...parsedResults];
                                                updatedResults[index].location = e.target.value; // Update the location field
                                                setParsedResults(updatedResults); // Update the state
                                            } } />
                                    </div>

                                    <div className="parsedResult-inputGroup">
                                        <label htmlFor={`linkedIn-${index}`} className="parsedResult-label">LinkedIn:</label>
                                        <input
                                            type="text"
                                            id={`linkedIn-${index}`}
                                            className="parsedResult-input"
                                            value={result.linkedIn || ""}
                                            onChange={(e) => {
                                                const updatedResults = [...parsedResults];
                                                updatedResults[index].linkedIn = e.target.value; // Update the LinkedIn field
                                                setParsedResults(updatedResults); // Update the state
                                            } } />
                                    </div>

                                    <button
                                        className="preview-button"
                                        onClick={() => handlePreviewFile(result.fileContent, result.contentType)}
                                    >
                                        Preview Resume
                                    </button>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
                </div>
                <button className="dashboard-add-job-button" onClick={onSubmit}>
                    Upload
                </button>
            </>
            )}
        </>
        )}
      </div>
    </div>
  );
}

export default UploadCandidatesModal;