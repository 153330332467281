import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import "./style.css"; 
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Editor } from "@tinymce/tinymce-react";
import axios from 'axios';
import PostScript from "../postScript";
import ReviewPosting from "../reviewScript";
import { Oval } from 'react-loader-spinner'; 

function PostPosition() {
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [locationForm, setLocationForm] = useState("");
  const [jobType, setJobType] = useState("Full-Time");
  const [description, setDescription] = useState("");
  const [isAutoSendEnabled, setIsAutoSendEnabled] = useState(false);
  const [jobLink, setJobLink] = useState("");
  const descRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [step, setStep] = useState(1); 
  const [generatedScript, setGeneratedScript] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const createInterviewEndpoint = process.env.REACT_APP_CREATE_COMPANY_INTERVIEW;

  const companyId = localStorage.getItem("companyId");

  const logDescription = () => {
    if (descRef.current) {
      setDescription(descRef.current.getContent());
    }
  };

  const handleFinalSubmit = async () => {
    const payload = {
      CompanyId: companyId, 
      JobDescription: description,
      Position: jobTitle,
      PositionType: jobType,
      PositionLocation: locationForm,
      GeneratedScript: JSON.stringify(generatedScript), 
      AutoInterview: isAutoSendEnabled,
    };
  
    try {
      const response = await axios.post(
        createInterviewEndpoint,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
  
  
      if (response.data.status === "Success") {
        alert("Job posting created successfully!");
        setJobLink(response.data.jobLink); 
  
        navigate("/dashboard", {
          state: {
            jobTitle: jobTitle,
            jobLink: response.data.jobLink,
            isPopupOpen: true,
          },
        });
      } else {
        console.error("API Error:", response.data);
        alert("Failed to create job posting. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred while submitting job posting:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (generatedScript) {
      setStep(2);
      return;
    }  
  
    const payload = {
      CompanyName: companyName, 
      Position: jobTitle,
      JobDescription: description,
    };

    setIsLoading(true); 
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/Chat/generateDynamicInterviewScript`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        const script = response.data;
  
        setGeneratedScript(response.data); 
        setStep(2); 

      } else {
        console.error("API Error:", response.data);
        alert("Failed to generate interview script. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred while sending API request:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false); // Hide loader after API call
    }
  };

  const RequiredSpan = styled(Typography)(() => ({
    fontFamily: "Montserrat, san-serif !important",
    color: "#FF0000",
    display: "inline",
    fontSize: "20px !important",
    marginLeft: "3px",
  }));

  // Form validation logic
  useEffect(() => {
    setIsFormValid(
      jobTitle.trim().length > 0 &&
      companyName.trim().length > 0 &&
      locationForm.trim().length > 0 &&
      jobType.trim().length > 0 &&
      description.trim().length > 0
    );
  }, [jobTitle, companyName, locationForm, jobType, description]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <Oval color="#00BFFF" height={80} width={80} />
          <div className="loading-text">
            Nora is generating your interview script based on the job description...
          </div>
        </div>
      )}

      <Navbar showSidebar={false} />
      {step === 1 && (
        <div className="post-position-container">
          <div className="post-position-header">Create Your Job Posting</div>
          <section className="post-position-section">
            <form className="post-position-form" onSubmit={handleSubmit}>
              <div className="post-position-form-group">
                <label className="post-position-label">
                  Job Title
                  <RequiredSpan>*</RequiredSpan>
                </label>
                <input
                  className="post-position-input"
                  type="text"
                  placeholder="e.g. Graphic Designer"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  required />
              </div>

              <div className="post-position-form-group">
                <label className="post-position-label">
                  Company Name
                  <RequiredSpan>*</RequiredSpan>
                </label>
                <input
                  className="post-position-input"
                  type="text"
                  placeholder="e.g. Apple or Stealth Startup"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>

              <div className="post-position-form-inline">
                <div className="post-position-form-group">
                  <label className="post-position-label">
                    Location
                    <RequiredSpan>*</RequiredSpan>
                  </label>
                  <input
                    className="post-position-input"
                    type="text"
                    placeholder="e.g. New York or Remote"
                    value={locationForm}
                    onChange={(e) => setLocationForm(e.target.value)}
                    required />
                </div>

                <div className="post-position-form-group">
                  <label className="post-position-label">
                    Type
                    <RequiredSpan>*</RequiredSpan>
                  </label>
                  <select
                    className="post-position-input"
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                  >
                    <option value="Full-Time">Full-Time</option>
                    <option value="Part-Time">Part-Time</option>
                    <option value="Intern">Intern</option>
                    <option value="Contracter">Contractor</option>
                  </select>
                </div>
              </div>

              <div className="post-position-form-group">
                <label className="post-position-label">
                  Description
                  <RequiredSpan>*</RequiredSpan>
                </label>

                <Editor
                  apiKey="jc8yk8kr00di4mb9o4q4qmctbxvmw8yko01y30p42h6xvsqp"
                  onInit={(evt, editor) => (descRef.current = editor)}
                  value={description}
                  onEditorChange={() => logDescription()}
                  init={{
                    placeholder: `1) Responsibilities - List key responsibilities of the role.
                          
                          2) Requirements - Specify essential qualifications/experience (e.g. 5+ years of experience).
                      
                          3) Candidate Must Have - State mandatory conditions such as start date, location, hours, and salary.
                      
                          4) Who We Are - Brief description of your company.`,
                    height: 450,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "table",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />

              </div>

              <div className="post-position-form-group" style={{textAlign: "center"}}>
                <label className="post-position-checkbox-label">
                  <input
                    type="checkbox"
                    className="post-position-checkbox"
                    checked={isAutoSendEnabled}
                    onChange={(e) => setIsAutoSendEnabled(e.target.checked)}
                  />
                  Automatically send an interview to every candidate?
                  <p className="post-position-checkbox-description">
                    All candidates who are uploaded or apply will automatically receive an email inviting them to take an AI interview with Nora.
                  </p>
                </label>
              </div>

              <div className="post-position-form-button">
                <button className="post-position-button" type="submit" disabled={!isFormValid}
                  style={{
                    backgroundColor: isFormValid ? "#1daf5c" : "#cccccc",
                    cursor: isFormValid ? "pointer" : "not-allowed",
                  }}>
                  Next
                </button>
              </div>
            </form>
          </section>
        </div>
      )}

      {step === 2 && (
        <PostScript
          script={generatedScript}
          onBack={() => setStep(1)}
          onNext={(updatedScript) => {
            setGeneratedScript(updatedScript); 
            setStep(3); 
          }}
        />
      )}

      {step === 3 && (
        <ReviewPosting
          jobTitle={jobTitle}
          companyName={companyName}
          locationForm={locationForm}
          jobType={jobType}
          description={description}
          generatedScript={generatedScript}
          onBack={() => setStep(2)}
          onSubmit={handleFinalSubmit}
          onEditJobPosting={() => setStep(1)} 
          onEditInterviewQuestions={() => setStep(2)} 
        />
      )}
    </>
  );
}

export default PostPosition;