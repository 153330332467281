import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "../RejectPopup/style.css";
import { useNavigate } from "react-router-dom";

function BulkInterviewWNoraPopup({
    isPopupVisible,
    onClose,
    selectedCandidates,
    companyName,
    position,
    setLoadingApplicantId, 
    setApplicants,         
    setEmailSentApplicantId,
    interviewScript,
    jobDescription,
    companyInterviewId
}) {
    const [subject, setSubject] = React.useState("");
    const [content, setContent] = React.useState("");
    const navigate = useNavigate();

    const sendBatchCandidateInterviewEndpoint = process.env.REACT_APP_SEND_BATCH_CANDIDATE_INTERVIEW;

    React.useEffect(() => {
        // Set default subject and content whenever the popup becomes visible
        if (isPopupVisible) {
            setSubject(`${position} - Interview Next Steps`);

            setContent(
                `Hi {Candidate_Name}, <br><br>We're pleased to inform you that ${companyName} would like to move forward with your application for our ${position} position!<br><br>As part of the next step, we're inviting you to complete an interview powered by Nora AI from Intern Guys!<br><br>Interview Link:<br><br>You can complete the interview at any time that's convenient for you. Simply click the link below to get started:<br><br>[INTERVIEW_URL]<br><br>We encourage you to do this as soon as possible, so we can continue with the next steps promptly.<br><br>If you have any questions or run into any issues, don't hesitate to reach out.<br><br>Team ${companyName}`
            );
        }
    }, [isPopupVisible, companyName, position]);

    const handleSendBatchEmail = async () => {
        if (!interviewScript) {
            navigate("/postPosition2", {
                state: {
                    companyName: companyName,
                    position: position,
                    jobDescription: jobDescription,
                    companyInterviewId: companyInterviewId
                }
            });
            return;
        }

        if (!subject || !content) {
            alert("Both Subject and Content are required.");
            return;
        }
    
        // Start loading for all selected candidates
        setLoadingApplicantId(selectedCandidates.map(c => c.candidateToInterviewId));
    
        try {
            // Prepare payload for batch send
            const payload = selectedCandidates.map(candidate => ({
                candidateToInterviewId: candidate.candidateToInterviewId,
                candidateName: candidate.name,
                candidateEmail: candidate.email,
                companyName: companyName,
                position: position,
                interviewUrl: candidate.interviewURL
            }));
    
            const response = await fetch(sendBatchCandidateInterviewEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                throw new Error("Failed to send batch interview emails.");
            }

            const responseData = await response.json();
    
            // Update local state to reflect changes
            setApplicants((prevApplicants) => 
                prevApplicants.map((applicant) => {
                    const isSuccessful = responseData.successfulCandidates.includes(applicant.candidateToInterviewId);
                    return isSuccessful 
                        ? { ...applicant, interviewWithNora: true } 
                        : applicant;
                })
            );
    
            // Set email sent for successful candidates
            responseData.successfulCandidates.forEach(candidateId => {
                setEmailSentApplicantId(candidateId);
            });
    
            // Clear the email sent indicator after a delay
            setTimeout(() => {
                setEmailSentApplicantId(null);
            }, 3000);
    
            onClose(); // Close the popup after successful email sending
            alert(`Sent interviews to ${responseData.successfulCandidates.length} candidates`);
            window.location.reload();
        } catch (error) {
            console.error("Error sending batch interview emails:", error);
            alert("Failed to send emails. Please try again.");
        } finally {
            // Stop the loading indicator
            setLoadingApplicantId(null);
        }
    };

    if (!isPopupVisible) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content" style={{ width: "750px" }}>
                <button
                    className="close-popup-button"
                    onClick={onClose}
                >
                    &times;
                </button>
                <h2>Bulk Interview with Nora Invite</h2>

                <label htmlFor="subject">Subject Line *</label>
                <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{
                        marginBottom: "10px",
                        padding: "10px",
                        width: "100%",
                    }}
                />

                <label htmlFor="content">Content *</label>
                <Editor
                    apiKey="jc8yk8kr00di4mb9o4q4qmctbxvmw8yko01y30p42h6xvsqp"
                    value={content}
                    onEditorChange={(content) => setContent(content)}
                    init={{
                        height: 350,
                        menubar: false,
                        plugins: [
                            "advlist", "autolink", "lists", "link", "charmap", "preview",
                            "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
                            "insertdatetime", "table", "code", "help", "wordcount",
                        ],
                        toolbar:
                            "undo redo | blocks | bold italic forecolor | " +
                            "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />

                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <button
                        className="send-email-button"
                        onClick={handleSendBatchEmail}
                        style={{ width: "200px", padding: "10px" }}
                    >
                        Send Batch Interviews
                    </button>
                </div>
            </div>
        </div>
    );
}

export default BulkInterviewWNoraPopup;