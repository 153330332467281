import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

const AudioPlayer = () => {
  const waveSurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let wavesurfer = null;

    const initWaveSurfer = async () => {
      // Create WaveSurfer instance
      wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#ddd',
        progressColor: '#ffaa00',
        cursorColor: '#ffaa00',
        height: 100,
        responsive: true,
        barWidth: 2,
        backend: 'MediaElement',
      });

      // Add event listeners
      wavesurfer.on('ready', () => {
        setIsLoaded(true);
        waveSurferRef.current = wavesurfer;
        wavesurfer.seekTo(0.006);
      });

      wavesurfer.on('error', (error) => {
        console.error('WaveSurfer error:', error);
      });

      // Load audio
      try {
        await wavesurfer.load('https://nora-audio-file.s3.us-west-1.amazonaws.com/Product+Designer+Interview+Demo+-+Samantha+(online-audio-converter.com)-%5BAudioTrimmer.com%5D.mp3');
      } catch (error) {
        console.error('Loading error:', error);
      }
    };

    initWaveSurfer();

    // Cleanup
    return () => {
      if (wavesurfer) {
        wavesurfer.unAll(); // Remove all event listeners
        wavesurfer.destroy();
      }
    };
  }, []);

  const togglePlay = () => {
    if (waveSurferRef.current && isLoaded) {
      waveSurferRef.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <button
        onClick={togglePlay}
        disabled={!isLoaded}
        style={{
          backgroundColor: '#ffaa00',
          border: 'none',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          color: 'white',
          fontSize: '16px',
          cursor: isLoaded ? 'pointer' : 'not-allowed',
          opacity: isLoaded ? 1 : 0.5,
        }}
      >
        {isPlaying ? '❚❚' : '▶'}
      </button>
      <div id="waveform" style={{ marginTop: '20px' }}></div>
    </div>
  );
};

export default AudioPlayer;