import React, {useState} from "react";
import "./style.css"; 

const PostScript = ({ script, onBack, onNext }) => {

  const [currentScript, setCurrentScript] = useState(script);
  const [draggedItem, setDraggedItem] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [removedBuckets, setRemovedBuckets] = useState([]); 
  const [suggestions, setSuggestions] = useState([]); 

  const handleNextClick = () => {
    onNext(currentScript); 
  };

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleNewQuestionChange = (e) => {
    setNewQuestion(e.target.value);
  };

  const handleNewQuestionSave = () => {
    if (newQuestion.trim() !== "") {
      setCurrentScript({
        ...currentScript,
        questions: [...currentScript.questions, newQuestion]
      });
      setNewQuestion("");
      setIsAdding(false);
    }
  };

  const handleNewQuestionCancel = () => {
    setNewQuestion("");
    setIsAdding(false);
  };

  const handleEditClick = (index, question) => {
    setEditingIndex(index);
    setEditingValue(question);
  };

  const handleEditChange = (e) => {
    setEditingValue(e.target.value);
  };

  const handleEditSave = (index) => {
    const updatedQuestions = [...currentScript.questions];
    updatedQuestions[index] = editingValue;
    setCurrentScript({ ...currentScript, questions: updatedQuestions });
    setEditingIndex(null);
  };

  const handleEditCancel = () => {
    setEditingIndex(null);
    setEditingValue("");
  };

  const handleDragStart = (e, index, type) => {
    setDraggedItem({ index, type });
  };

  const handleDragOver = (e, index, type) => {
    e.preventDefault();
    if (!draggedItem) return;

    if (draggedItem.type === type) {
      const newScript = { ...currentScript };
      
      if (type === 'bucket') {
        const buckets = [...newScript.buckets];
        const [draggedBucket] = buckets.splice(draggedItem.index, 1);
        buckets.splice(index, 0, draggedBucket);
        newScript.buckets = buckets;
      } else if (type === 'question') {
        const questions = [...newScript.questions];
        const [draggedQuestion] = questions.splice(draggedItem.index, 1);
        questions.splice(index, 0, draggedQuestion);
        newScript.questions = questions;
      }
      
      setCurrentScript(newScript);
      setDraggedItem({ index, type });
    }
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...currentScript.questions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    setCurrentScript({ ...currentScript, questions: updatedQuestions });
  };

  const handleRemoveBucket = (index) => {
    const removedBucket = currentScript.buckets[index];
    const updatedBuckets = [...currentScript.buckets];
    updatedBuckets.splice(index, 1);
  
    setCurrentScript({ ...currentScript, buckets: updatedBuckets });
  
    // Add the removed bucket to suggestions (with examples for re-adding later)
    setSuggestions([...suggestions, removedBucket.name]);
    setRemovedBuckets([...removedBuckets, removedBucket]); // Store the full bucket
  };

  const handleAddBucketFromSuggestion = (suggestion) => {
    // Retrieve the full bucket from removedBuckets
    const bucketToAdd = removedBuckets.find((bucket) => bucket.name === suggestion);
  
    if (bucketToAdd) {
      const updatedBuckets = [...currentScript.buckets, bucketToAdd];
      setCurrentScript({ ...currentScript, buckets: updatedBuckets });
  
      // Remove the suggestion and bucket from removedBuckets
      setSuggestions(suggestions.filter((s) => s !== suggestion));
      setRemovedBuckets(removedBuckets.filter((bucket) => bucket.name !== suggestion));
    }
  };

  return (
    <div className="post-script-container">
        <h1>Nora's Generated Questions</h1>
        <p>Customize the interview questions below to better align with the role. Click next when you're ready to proceed.</p>
        
        <div className="script-content">
        {currentScript.buckets.map((bucket, index) => (
          <div
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index, 'bucket')}
            onDragOver={(e) => handleDragOver(e, index, 'bucket')}
            onDragEnd={handleDragEnd}
            className="bucket"
          >
            <div className="bucket-header">
              <span className="reorder-icon">☰</span>
              <span className="bucket-name">{bucket.name}</span>

              <button className="remove-icon" onClick={() => handleRemoveBucket(index)}>✖</button>
            </div>

            <div className="bucket-body">
              {bucket.examples.map((example, exampleIndex) => (
                <p key={exampleIndex} className="example">Example {example}</p>
              ))}
            </div>
          </div>
        ))}

        <div className="additional-questions">
          {currentScript.questions.map((question, index) => (
            <div
              key={index}
              draggable
              onDragStart={(e) => handleDragStart(e, index, 'question')}
              onDragOver={(e) => handleDragOver(e, index, 'question')}
              onDragEnd={handleDragEnd}
              className="additional-question"
            >
              <span className="reorder-icon">☰</span>
              {editingIndex === index ? (
                <div className="edit-question">
                  <input
                    type="text"
                    value={editingValue}
                    onChange={handleEditChange}
                    className="edit-input"
                  />
                  <button
                    className="save-button"
                    onClick={() => handleEditSave(index)}
                  >
                    Save
                  </button>
                  <button
                    className="cancel-button"
                    onClick={handleEditCancel}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <span
                  className="question-text"
                  onClick={() => handleEditClick(index, question)}
                >
                  {question}
                </span>
              )}
              <button className="remove-icon" onClick={() => handleRemoveQuestion(index)}>✖</button>
            </div>
          ))}

          {isAdding ? (
            <div className="new-question">
              <input
                type="text"
                value={newQuestion}
                onChange={handleNewQuestionChange}
                placeholder="Type your question here..."
                className="new-question-input"
              />
              <button className="save-button" onClick={handleNewQuestionSave}>
                Save
              </button>
              <button className="cancel-button" onClick={handleNewQuestionCancel}>
                Cancel
              </button>

              {/* Add suggestions logic here */}
              {suggestions.length > 0 && (
                <div className="suggestions">
                    <span>Suggested:</span>
                    {suggestions.map((suggestion, index) => (
                    <button
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleAddBucketFromSuggestion(suggestion)}
                    >
                        {suggestion}
                    </button>
                    ))}
                </div>
                )}
            </div>
          ) : (
            <button className="add-question-button" onClick={handleAddClick}>
              <span className="plus-icon">+</span>
            </button>
          )}
        </div>
      </div>

        <div className="post-script-buttons">
            <button className="post-script-button" onClick={onBack}>
                Back
            </button>
            <button className="post-script-button" onClick={handleNextClick}>
                Next
            </button>
        </div>
    </div>
  );
};

export default PostScript;