import React, { useState } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import NoraAI_icon from '../../images/nora-ai-logo.png';

function NavigationBar() {
  let navigate = useNavigate();
  const [hamburger_status, setHamburger_status] = useState(false);

  const change_hamburger = (e) => {
    e.preventDefault();
    setHamburger_status(!hamburger_status);
  };

  const change_nav = (e, path) => {
    e.preventDefault();
    navigate(`${path}`);
  };

  return (
    <>
      {/* <PortalNav /> */}
      <div className="nav_container">
        <div className="nav_left">
          <div className="navbar_logo">
            <img
              src={NoraAI_icon}
              alt="Intern Guys"
              onClick={(e) => change_nav(e, '/')}
            />
          </div>
        </div>

        <div className="nav_right">
          <a href="" onClick={(e) => change_nav(e, '/case-study')}>
            <div className="nav_get_started">
              <p>Case Study</p>
            </div>
          </a>

          <a href="" onClick={(e) => change_nav(e, '/login')}>
            <div className="nav_login">
              <p>Sign In</p>
            </div>
          </a>
          <a href="https://calendly.com/nora-hq/demo-questionnaire"
            target="_blank"
            rel="noopener noreferrer">
            <div className="nav_get_started">
              <p>Book a Demo</p>
            </div>
          </a>
        </div>

        <div className="hamburger_nav">
          <div className="hamburger_left">
            <div className="navbar_logo">
              <img
                src={NoraAI_icon}
                alt="Intern Guys"
                onClick={() => navigate('/')}
              />
            </div>
          </div>
          <div className="hamburger_right">
            {!hamburger_status ? (
              <div
                className={`hamburger_logo ${
                  hamburger_status ? 'inactive' : ''
                }`}
                onClick={(e) => {
                  change_hamburger(e);
                }}
              >
                <div className="nav_bar"></div>
                <div className="nav_bar"></div>
                <div className="nav_bar"></div>
              </div>
            ) : (
              <div
                className="close_icon"
                onClick={(e) => {
                  change_hamburger(e);
                }}
              >
                <span></span>
                <span></span>
              </div>
            )}
          </div>
        </div>
      </div>
      {hamburger_status ? (
        <div className="dropdown">
          <a href="" onClick={(e) => change_nav(e, '/case-study')}>
              <p>Case Study</p>
          </a>

          <a href="" onClick={(e) => change_nav(e, '/login')}>
              <p>Sign In</p>
          </a>
          <a href="https://calendly.com/nora-hq/demo-questionnaire"
            target="_blank"
            rel="noopener noreferrer">
              <p>Book a Demo</p>
          </a>
          <a href="https://www.internguys.com/">
              <p>Mock Interviewer</p>
          </a>
        </div>
      ) : null}
    </>
  );
}

export default NavigationBar;
