import React from 'react';
import { useMediaQuery } from '@mui/material';
import NavigationBar from '../../components/navigation';
import Footer from '../../components/footer';
import "./style.css";
import DemoCallBackground from '../../images/homepage/demoCallBackground.png';
import JackCraicker from "../../images/homepage/jackCraicker.png";
import CaseStudyRoi from "../../images/homepage/caseStudyRoi.png";
import CaseStudyRoiMobile from "../../images/homepage/caseStudyRoiMobile.png";

function CaseStudy() {
  const isMobile = useMediaQuery('(max-width:600px)');
    
  return (
    <div className="hero_container">
      <NavigationBar />

      <h1 className="hero_title-2">
        {isMobile 
          ? "Case Study: Opportunity Knocks" 
          : "Case Study: How Opportunity Knocks Reclaimed 36+ Hours Monthly with Nora AI"}
      </h1>

      <div className="profile-container">
        <div className="profile-image">
        <img src={JackCraicker} alt="Jack Craiker" className="profile-img" />
        </div>
        <div className="profile-label">With Jack Craiker, Founder & CEO</div>
      </div>

      <p className="hero_subtitle-2">
        "Nora AI has changed everything. Interviewing used to consume hours of my day and now it takes just seconds, allowing me to focus on growing my business."
        <br />— Jack Craiker
      </p>

      <img src={isMobile ? CaseStudyRoiMobile : CaseStudyRoi} 
        alt="Case Study ROI" 
        className="roi-img" 
      />

      <section className="challenge-section">
        <h2 className="section-title">The Challenge</h2>
        <div className="section-divider"></div>
        
        <div className="section-content">
          <p className="challenge-description">
            As the founder and CEO of Opportunity Knocks, Jack Craiker was personally handling 
            the hiring process for entry-level positions and internships. This meant:
          </p>
          
          <ul className="challenge-list">
            <li>Conducting <strong>100+ interviews</strong>manually </li>
            <li>Spending an average of 25 minutes per candidate</li>
            <li>Over <strong>40+ hours per month</strong> spent solely on initial candidate screening</li>
            <li>Limited ability to scale hiring efforts</li>
            <li>Inconsistent interview experiences between candidates</li>
            <li>Difficulty maintaining structured evaluation criteria</li>
          </ul>
          
          <p className="challenge-conclusion">
            The intensive time investment was preventing Jack from focusing on strategic business growth.
          </p>
        </div>
      </section>

      <section className="challenge-section2">
        <h2 className="section-title">The Solution: Nora AI</h2>
        <div className="section-divider"></div>
        
        <div className="section-content">
          <p className="challenge-description">
            In January 2025, Opportunity Knocks implemented Nora AI's automated interviewing 
            solution via our <strong>Starter Plan</strong> ($399/month).
          </p>
          
          <p className="challenge-description">Key Implementation Details:</p>

          <ul className="challenge-list">
            <li><strong>Fully automated</strong>candidate screening and interviewing process</li>
            <li>Nora conducts consistent interview process for all applicants</li>
            <li><strong>Standardized evaluation criteria</strong></li>
            <li>Comprehensive candidate interview reports</li>
            <li>Quick review system with <strong>at-a-glance scoring</strong></li>
          </ul>
        </div>
      </section>

      <section className="challenge-section2">
        <h2 className="section-title">Results That Drive Change</h2>
        <div className="section-divider"></div>
        
        <div className="section-content">
          <div className="result-item">
            <h3 className="result-subtitle">1. Time Savings</h3>
            <ul className="challenge-list">
              <li>90% reduction in hiring time</li>
              <li>Interview review process reduced from 25 minutes to mere seconds per candidate</li>
              <li>Total time saved: Approximately 36+ hours per month</li>
            </ul>
          </div>

          <div className="result-item">
            <h3 className="result-subtitle">2. Improved Hiring Quality</h3>
            <ul className="challenge-list">
              <li>Structured, consistent interview process for all candidates</li>
              <li>Standardized evaluation criteria ensuring fair assessment</li>
              <li>Higher quality candidates identified through comprehensive reports</li>
            </ul>
          </div>

          <div className="result-item">
            <h3 className="result-subtitle">3. Increased Capacity</h3>
            <ul className="challenge-list">
              <li>Successfully screened over 300 candidates since implementation</li>
              <li>Ability to process significantly more applicants without additional time investment</li>
              <li>Freed up valuable leadership time for strategic business initiatives</li>
            </ul>
          </div>

          <div className="result-item">
            <h3 className="result-subtitle">4. Return on Investment</h3>
            <ul className="challenge-list">
              <li>Monthly subscription cost: $399</li>
              <li>Value of time saved (based on average recruiter hourly rate): $2,000+</li>
              <li>ROI of over 400% in the first month alone</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="challenge-section2">
        <h2 className="section-title">Looking Forward</h2>
        <div className="section-divider"></div>
        
        <div className="section-content">
          <p className="challenge-description">
            Jack has been highly satisfied with Nora AI's core functionality and is now looking to:
          </p>
          
          <ul className="challenge-list">
            <li><strong>Implement API/SDK access for deeper integration</strong> with existing systems</li>
            <li>Further streamline the candidate selection process</li>
            <li><strong>Scale hiring capabilities</strong> as the company grows</li>
          </ul>
        </div>
      </section>

      <div className="component_five_container" 
        style={{
          ...(isMobile
            ? { width: '85%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }
            : {
                backgroundImage: `url(${DemoCallBackground})`,
                width: '85%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }),
        }}
      >
        <h2 className="calendly_header" id="component_five">Ready to elevate your interviewing game?</h2>
        <div className="calendly_subtext">
          Simplify interviewing. Smarter hiring.
        </div>
        <button className="demo_request_button" 
        onClick={() => window.open('https://calendly.com/nora-hq/demo-questionnaire', '_blank')}>
          Request a demo
        </button>
      </div>

      <Footer />
    </div>
  );
}

export default CaseStudy;