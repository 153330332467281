import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import "./styles.css";
import $ from "jquery";
import React from 'react';
import { useNavigate, } from 'react-router-dom';
import PositionInfo from "../../components/positionInfo";
import JobPostingPopup from "../../components/jobPostingPopup";
import EditPositionPopup from "../../components/editPosition";
import { useMediaQuery } from '@mui/material';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { Oval } from 'react-loader-spinner'; 

function dashboard() {
  const navigate = useNavigate();
  const [refresh_internship,setRefresh_internship]=useState(true);
  
  const routerLocation = useLocation();
  const state = routerLocation.state || {};
  const [isPopupOpen, setIsPopupOpen] = useState(state.isPopupOpen || false);
  const [jobTitle, setJobTitle] = useState(state.jobTitle || "");
  const [jobLink, setJobLink] = useState(state.jobLink || "");
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [apiKeyStatus, setApiKeyStatus] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [mergeIntegrationActive, setMergeIntegrationActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [positionsPerPage] = useState(10);

  const [positions, setPositions] = useState([]);

  const getCompanyInterviewsEndpoint = process.env.REACT_APP_GET_COMPANY_INTERVIEWS;
  const createApiKeyEndpoint = process.env.REACT_APP_CREATE_API_KEY;
  const getApiKeyEndpoint = process.env.REACT_APP_GET_API_KEY;
  const [linkToken, setLinkToken] = useState(null);

  // First, define filtered positions with a safety check
  const filteredPositions = positions.filter(positionObj =>
    positionObj && (
      // Check title property if it exists
      (positionObj.title && positionObj.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      // Check position property if it exists
      (positionObj.position && positionObj.position.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  );

  // Then use a conditional to determine which array to use based on whether search is active
  // Add this after your filteredPositions calculation
  const positionsToUse = searchTerm ? filteredPositions : positions;

  // Update these calculations to use positionsToUse
  const indexOfLastPosition = currentPage * positionsPerPage;
  const indexOfFirstPosition = indexOfLastPosition - positionsPerPage;
  const currentPositions = positionsToUse.slice(indexOfFirstPosition, indexOfLastPosition);
  const totalPages = Math.ceil(positionsToUse.length / positionsPerPage);

  // Functions to handle pagination
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: (publicToken) => {
      const companyId = localStorage.getItem("companyId");
      
      $.ajax({
        url: process.env.REACT_APP_EXCHANGE_MERGE_TOKEN,
        type: "POST",
        data: JSON.stringify({
          companyId: companyId,
          publicToken: publicToken
        }),
        contentType: "application/json",

        success: function(response) {          
          setIsLoading(true);
          // After successful token exchange, immediately sync ATS positions
          $.ajax({
            url: process.env.REACT_APP_ADD_ATS_POSITIONS,
            type: "POST",
            data: JSON.stringify(companyId),
            contentType: "application/json",
            success: function(syncResponse) {
              setIsLoading(false);
              alert("ATS connected and positions imported successfully!");
              window.location.reload(); // Refresh to show new positions
            },
            error: function(syncErr) {
              console.error("Error syncing ATS positions:", syncErr);
              setIsLoading(false);
              alert("ATS connected, but there was an error importing positions. Please try again.");
              window.location.reload(); // Still reload to show ATS connection
            }
          });
        },
        
        error: function(err) {
          console.error("Error exchanging token:", err);
          alert("Failed to connect ATS. Please try again.");
        }
      });
    }
  });

  const handleSyncATS = () => {
    const companyId = localStorage.getItem("companyId");

    $.ajax({
      url: process.env.REACT_APP_SYNC_ATS_POSITIONS,
      type: "POST",
      data: JSON.stringify(companyId),
      contentType: "application/json",
      success: function(syncResponse) {
        alert("ATS positions synced successfully!");
        window.location.reload();
      },
      error: function(syncErr) {
        console.error("Error syncing ATS positions:", syncErr);
        
        // Show error message to the user
        alert("Failed to sync ATS positions. Please try again.");
      }
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    setCopySuccess(true);

    // Reset copy success after 2 seconds
    setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");

    if (!companyId) {
      localStorage.clear(); // Clear any stored data
      navigate("/login"); // Redirect to login page
    }
    
    if (!companyId) {
      return;
    }

    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    setIsLoading2(true);

    // Fetch interviews
    $.get(
      `${getCompanyInterviewsEndpoint}?companyId=${companyId}`,
      (response) => {
        setPositions(response);
        setMergeIntegrationActive(response[0].mergeIntegrationActive);
        setIsLoading2(false);
      }
    ).fail((err) => {
      console.error("Error fetching company interviews:", err);
      setIsLoading2(false);
    });

    // Fetch API key
    $.get(
      `${getApiKeyEndpoint}?companyId=${companyId}`,
      (response) => {
        setApiKey(response.apiKey);
        setApiKeyStatus(response.apiKeyStatus);
      }
    ).fail((err) => {
      console.error("Error fetching API key:", err);
    });
  }, [getCompanyInterviewsEndpoint]);

  const update_refresh =() =>{
    setRefresh_internship(!refresh_internship);
  }

  const handleOpenPopup = (title, link) => {
    setJobTitle(title);
    setJobLink(link);
    setIsPopupOpen(true);
  };

  const handleEditPopup = (position) => {
    setSelectedPosition(position);
    setIsEditPopupOpen(true);
  };

  const handleCloseEditPopup = () => {
    setIsEditPopupOpen(false);
  };

  const handleCreateApiKey = () => {
    const companyId = localStorage.getItem("companyId");
    
    $.ajax({
      url: createApiKeyEndpoint,
      type: "POST",
      data: JSON.stringify({ companyId: companyId }),
      contentType: "application/json",
      success: function(response) {
        setApiKey(response.apiKey);
        window.location.reload();
      },
      error: function(err) {
        console.error("Error generating API key:", err);
      }
    });
  };

  const handleConnectATS = () => {
    const companyId = localStorage.getItem("companyId");
    const userEmail = localStorage.getItem("userEmail");
    
    $.ajax({
      url: process.env.REACT_APP_CREATE_LINK_TOKEN,
      type: "POST",
      data: JSON.stringify({ 
        companyId: companyId,
        userEmail: userEmail
      }),
      contentType: "application/json",
      success: function(response) {
        // Set the link token, which will update the useMergeLink hook
        setLinkToken(response.linkToken);
        // Open the merge link dialog after a short delay to ensure the hook has updated
        setTimeout(() => {
          if (isReady) {
            open();
          }
        }, 100);
      },
      error: function(err) {
        console.error("Error getting Merge link token:", err);
      }
    });
  };

  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <Oval color="#00BFFF" height={80} width={80} />
          <div className="loading-text">
            Nora is currently extracting your ATS information (approx 1-2 minutes)
          </div>
        </div>
      )}
      {isLoading2 && (
        <div className="loading-overlay">
          <Oval color="#00BFFF" height={80} width={80} />
          <div className="loading-text">
            Nora is retrieving your positions and applicants! 
          </div>
        </div>
      )}
      <Navbar showSidebar={true} />
      <div className="dashboard-container">
          <div>
            {isPopupOpen && (
              <JobPostingPopup
                jobTitle={jobTitle}
                jobLink={jobLink}
                onClose={handleClosePopup}
              />
            )}

            {isEditPopupOpen && (
              <EditPositionPopup
                position={selectedPosition}
                onClose={handleCloseEditPopup}
                update_refresh={update_refresh}
              />
            )}
        </div>
        <article className="glass-container">
          <div className="flex-row-container-header"> 
          <h1 className="title">Job Postings </h1>

          {/* Search input placed immediately after the title */}
          <div className="search-container-inline">
            <input
              type="text"
              className="search-input"
              placeholder="Search positions by title"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <div style={{ marginLeft: "auto", display: "flex", gap: "10px" }}>
            {!mergeIntegrationActive && (
              <button 
                className="dashboard-add-job-button5" 
                onClick={handleConnectATS}
              >
                Connect ATS
              </button>
            )}

            {positions.length !== 0 && (
              <button
                className="dashboard-add-job-button3"
                onClick={() => {
                  const companyId = localStorage.getItem("companyId");
                  navigate("/postPosition", { state: { companyId } });
                }}
              >
                + Job Posting
              </button>
            )}
          </div>

          </div>
          <div className="dashboard-internship-title-container-main">
            {!isMobile && ( 
              <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>Status</div>
            )}
            <div className="dashboard-internship-info-title">Position</div>
            <div className="dashboard-internship-info-title">Applicants</div>
            <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>Job Link</div>
            {!isMobile && ( 
              <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>More</div>
            )}
          </div>

          <hr className="dashboard-divider-line" />

          <div className="dashboard-internships-info-container">
            {positions.length !== 0 ? (
              <>
                {positionsToUse.length > 0 ? (
                  currentPositions.map((position, index) => {
                    return (
                      <PositionInfo
                        positionInfo={{
                          ...position,
                          onOpenPopup: handleOpenPopup,
                          onEditPopup: handleEditPopup  
                        }}
                        key={index}
                        update_refresh={update_refresh}
                      />
                    );
                  })
                ) : (
                  <div className="no-results-message">
                    No positions match your search criteria.
                  </div>
                )}
                
                {/* Pagination controls */}
                {positionsToUse.length > positionsPerPage && (
                  <div className="pagination-controls">
                    <button 
                      className="pagination-button" 
                      onClick={goToPreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span className="pagination-info">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button 
                      className="pagination-button" 
                      onClick={goToNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="dashboard-zero-internships">
                <p className="dashboard-zero-internships-text">
                  You currently do not have <em>any</em> active job postings. <br />
                  Start recruiting <strong>NOW</strong> by adding a new job posting below.
                </p>
                <button
                  className="dashboard-add-job-button2"
                  onClick={() => {
                    const companyId = localStorage.getItem("companyId");
                    navigate("/postPosition", { state: { companyId } });
                  }}
                >
                  + Job Posting
                </button>
              </div>
            )}
          </div>
          
        </article>
        
        <div style={{ paddingTop: "20px" }}>
          <article className="glass-container">
            <div className="flex-row-container-header"> 
            <h1 className="title">API Key</h1>
              {!apiKey && (
                <button
                  className="dashboard-add-job-button3"
                  onClick={handleCreateApiKey}
                >
                  Create API Key
                </button>
              )}
            </div>

            {apiKey && (
              <div className="api-key-container">
                <div className="api-key-label">Your API Key:</div>
                <div className="api-key-box">
                  <div className="api-key-text">{apiKey}</div>
                  <button 
                    className="copy-button" 
                    onClick={copyToClipboard}
                    title="Copy to clipboard"
                  >
                    {copySuccess ? "Copied!" : <span class="clipboard-icon">&#x1F4CB;</span>} 
                  </button>
                </div>
                <div className="api-key-instructions">
                  This API key allows you to integrate with Nora's services. Keep it secure and do not share it publicly.
                </div>
              </div>
            )}
          </article>
        </div>

        {/* {mergeIntegrationActive && (
          <div style={{ paddingTop: "20px" }}>
            <article className="glass-container">
              <div className="flex-row-container-header">
                <h1 className="title">ATS Integration</h1>
                <button
                  className="dashboard-add-job-button4"
                  onClick={handleConnectATS}
                >
                  Connect ATS
                </button>
              </div>
              <div className="api-key-instructions">
                Relink ATS Integration and/or add a new ATS
              </div>
            </article>
          </div>
        )} */}

      </div>
    </div>
  );
}

export default dashboard;