import React from 'react';
import './style.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaTiktok } from 'react-icons/fa';
import PrivacyPolicy from '../../assets/Legal/PrivacyPolicy.pdf';
import TermsofService from '../../assets/Legal/TermsofService.pdf';
import NoraAI_icon from '../../images/nora-ai-logo.png';

const Footer = (props) => {
  //change the footer folder name comment
  return (
    <footer className="homepage_footer">
      <div className="footer-line"></div>
      <div className="footer-content">
        <div className="footer-logo-container">
          <img
            src={NoraAI_icon}
            alt="Intern guys"
            className="footer_logo"
          />
        </div>
        <div className="footer-content-columns-contain">
          <div className="footer-content-columns">
            <h3>For Candidates</h3>
            <p><a href="https://www.internguys.com/">Home</a></p>
            <p> <a href="https://www.internguys.com/blog">Blog</a></p>
          </div>
          <div className="footer-content-columns">
            <h3>For Recruiters</h3>
            <p><a href="https://norahq.com/login">Login</a></p>
            <p>  <a href="mailto:hannan@norahq.com">Contact</a></p>
          </div>
          <div className="footer-social-icons-small footer-content-columns">
            <a href="https://www.instagram.com/internguys/?hl=en" target="_blank" rel="noreferrer">
              <InstagramIcon  className="sc-icons"/>
            </a>
            <a href="https://www.linkedin.com/company/norahq" target="_blank" rel="noreferrer">
              <LinkedInIcon className="sc-icons"/>
            </a>
            <a href="https://www.tiktok.com/@internguys" target="_blank" rel="noreferrer">
              <FaTiktok className="sc-icons"/>
            </a>
          </div>
        </div>
        
        
      </div>
      <div className="footer-links">
        <div className="footer-doc">
          <div className="footer-doc-txt">
            <p><strong>Nora AI.</strong></p>
            <p className="right-text">All Rights Reserved</p>
          </div>
          <a href = {PrivacyPolicy} target = "_blank">Privacy Policy</a>
          <a href = {TermsofService} target = "_blank">Terms of Service</a>
        </div>
        <div className="footer-social-icons">
          <a href="https://www.instagram.com/internguys/?hl=en" target="_blank" rel="noreferrer">
            <InstagramIcon  className="sc-icons"/>
          </a>
          <a href="https://www.linkedin.com/company/norahq" target="_blank" rel="noreferrer">
            <LinkedInIcon className="sc-icons"/>
          </a>
          <a href="https://www.tiktok.com/@internguys" target="_blank" rel="noreferrer">
            <FaTiktok className="sc-icons"/>
          </a>
        </div>
      </div>
      {/* <div className="footer_grid">
        <nav className="footer_nav">
          <div>
            <h3>Students</h3>
            <Link to="/#how-does-it-work">How It Works</Link>
            <Link to="/resumaker">Resume Builder</Link>
            <Link to="/signup">Find Internships</Link>
            <Link to="/signup">Employers</Link>
            <Link to="/signup">Sign Up</Link>
          </div>
          <div>
            <h3>Employers</h3>
            <Link to="/">How It Works</Link>
            <Link to="/">Products</Link>
            <Link to="/">Register</Link>
          </div>
          <div className="business_column">
            <h3>Business</h3>
            <Link to="/">How It Works</Link>
            <Link to="/">Products</Link>
            <Link to="/">Register</Link>
          </div>
          <div className="company_column">
            <h3>Company</h3>
            <Link to="/about">About</Link>
            <Link to="/">How It All Started</Link>
            <Link to="/">Join Us</Link>
          </div>
        </nav>
        <div className="footer_subfooter">
          <span className="logo-container">
            <img
            src={IG_icon}
            alt="Intern guys"
            className="footer_logo"
            />    
            <p style={{margin:0,marginTop:'11px'}}> . All rights reserved.</p>
          </span>
          <div className="footer-right">
            <div className="noSmall">
            <a href = {PrivacyPolicy} target = "_blank">Privacy Policy</a>
            <a href = {TermsofService} target = "_blank">Terms of Service</a>
            </div>
            <div className="footer-social-icons">
              <a href="https://www.instagram.com/internguys/?hl=en" target="_blank" rel="noreferrer">
                <InstagramIcon  className="sc-icons"/>
              </a>
              <a href="https://www.linkedin.com/company/intern-guys/" target="_blank" rel="noreferrer">
                <LinkedInIcon className="sc-icons"/>
              </a>
              <a href="https://www.tiktok.com/@internguys" target="_blank" rel="noreferrer">
                <FaTiktok className="sc-icons-tiktok"/>
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
